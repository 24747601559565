export function dayToKST(date: string) {
	const day = new Date(date)
		.toLocaleDateString()
		.replaceAll(' ', '')
		.slice(0, -1);
	return day;
}
export function timeToKST(date: string) {
	const time = new Date(date).toString().slice(16, 24);
	return time;
}

// eslint-disable-next-line camelcase
export function dateString(updated_at: string): string {
	return `${dayToKST(updated_at).slice(2)} ${timeToKST(updated_at)}`;
}
