import React, { useCallback } from 'react';

import type { HandlerFunc } from '@/types';

interface BtnProps {
	type?: 'button' | 'submit' | 'reset' | undefined;
	children?: JSX.Element;
	className?: string;
	onClick?: HandlerFunc;
	disabled?: boolean;
	[key: string]: any;
}

function ActionButton({
	type,
	onClick,
	className,
	children,
	disabled,
	...props
}: BtnProps) {
	// const eventhandler = useCallback(() => onClick, []);
	return (
		<button
			type={type === 'button' ? 'button' : 'submit'}
			className={`${disabled ? ` invisible` : ``}${className}`}
			disabled={disabled}
			tabIndex={0}
			onClick={onClick}
			{...props}
		>
			{children}
		</button>
	);
}

export default React.memo(ActionButton);

ActionButton.defaultProps = {
	type: 'button',
	children: '',
	className: '',
	disabled: false,
	onClick: () => null,
};
