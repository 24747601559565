import React from 'react';

/** components */
import { Button } from 'antd';
import { LinkBtn } from '@/components';

/** css */
import 'antd/lib/button/style/index.css';
import { useNavigate, useParams } from 'react-router-dom';

/** util */
import { QUESTION_LIST } from '@/utils';

interface HandlerPros {
	handleCheckAll: () => void;
	handleDeleteSelected: () => void;
	handleDeleteAll: () => void;
}

export default function PostHeader({
	handleCheckAll,
	handleDeleteSelected,
	handleDeleteAll,
}: HandlerPros) {
	const navigate = useNavigate();
	const { id } = useParams();

	return (
		<>
			<div className="flex w-full items-center justify-center ">
				<h1 className="responsive-header-text font-bold w-full text-[38px] relative">
					SKA Automated Scoring System
				</h1>
				<div className="flex items-center justify-end">
					<Button
						type="primary"
						ghost
						onClick={() => navigate(`/${QUESTION_LIST}/${id}/write`)}
					>
						+ 새 글 추가
					</Button>

					<Button
						className="ml-2"
						type="primary"
						ghost
						onClick={handleCheckAll}
					>
						전체선택
					</Button>
					<Button
						className="ml-2"
						type="ghost"
						danger
						onClick={handleDeleteSelected}
					>
						선택삭제
					</Button>
					<Button
						className="ml-2"
						type="primary"
						danger
						onClick={handleDeleteAll}
					>
						전체삭제
					</Button>
				</div>
			</div>
		</>
	);
}
