import React, { useEffect, useState } from 'react';

// components
import { UpdateWithCancel } from '@/components';

// editor
import {
	Editor,
	EditorState,
	ContentState,
	CompositeDecorator,
} from 'draft-js';
import 'draft-js/dist/Draft.css';

import { cls, TEXT_LIMIT } from '@/utils';
import { useRedux } from '@/hooks';
import type { DetailDraftProps } from '@/types';
import { callbackify } from 'util';

function SearchHighlight({ children, color }: any) {
	return <span className={`${color} px-1`}>{children}</span>;
}
function underLine({ children, color }: any) {
	return (
		<span className={`text-red ${color} decoration-wavy underline px-1`}>
			{children}
		</span>
	);
}

const detailDecoratorHightLight = (highlightTerm: string[], color: string) =>
	new CompositeDecorator([
		{
			strategy: (contentBlock, callback) => {
				if (highlightTerm.length > 0) {
					const orgStr = highlightTerm
						.map((i) => `${i}`)
						.filter((i) => i !== '.');

					const regex = new RegExp(
						orgStr.join('|').replaceAll('?', '\\?'),
						'g',
					);

					let matchArr;
					let start;
					while (matchArr !== null) {
						matchArr = regex.exec(
							` ${contentBlock.getText().replace(/[\\(\\)]/, ' ')} `,
						);

						if (matchArr) {
							start = matchArr.index - 1;
							callback(start, start + matchArr[0].trim().length);
						}
					}
				}
			},
			component: SearchHighlight,
			props: { color },
		},
	]);

const detailDecoratorUnderLine = (underlineTerm: string[], color: string) =>
	new CompositeDecorator([
		{
			strategy: (contentBlock, callback) => {
				if (underlineTerm.length > 0) {
					const orgStr = underlineTerm
						.map((i) => `${i}`)
						.filter((i) => i !== '.');

					const regex = new RegExp(orgStr.join('|'), 'g');

					let matchArr;
					let start;
					while (matchArr !== null) {
						matchArr = regex.exec(
							` ${contentBlock.getText().replace(/[\\(\\)]/, ' ')} `,
						);

						if (matchArr) {
							start = matchArr.index - 1;
							callback(start, start + matchArr[0].trim().length);
						}
					}
				}
			},
			component: SearchHighlight,
			props: { color },
		},
	]);

export default function DetailDraft({
	fetchPostData,
	editor,
	editorLocation = { width: 0, height: 0 },
	editorLocateRef,
	title,
	isFocused,
	editorTitleFocusRef,
	text,
	handleFocusing,
	handleOk,
	handleCancel,
}: DetailDraftProps) {
	const { editorState, setEditorState } = editor;
	const { titleValue, setTitleValue } = title;
	const { newText, setNewText } = text;
	const { selectedFeatureItem } = useRedux('detail');

	const [textLengthValid, setTextLengthValid] = useState<boolean>(false);

	useEffect(() => {
		if (fetchPostData) {
			const { content } = fetchPostData;
			setNewText(content);
			setEditorState(
				EditorState.createWithContent(ContentState.createFromText(content)),
			);
		}
	}, [fetchPostData]);

	/** 스로틀 혹은 디바운스 */
	// editorState에 Focus와 Blur가 다 들어가있기 때문에 useEffect에 자꾸 걸린다.
	useEffect(() => {
		const prevText = editorState.getCurrentContent().getPlainText();

		if (prevText.length >= TEXT_LIMIT) {
			const temp = prevText.slice(0, TEXT_LIMIT - 1);
			const $editorState = EditorState.moveFocusToEnd(
				EditorState.createWithContent(ContentState.createFromText(temp)),
			);
			setEditorState($editorState);
			setTextLengthValid(true);
		}

		setNewText(editorState.getCurrentContent().getPlainText());
	}, [editorState]);

	useEffect(() => {
		if (textLengthValid) alert('3000자 이상을 넘을 수 없습니다.');

		return () => setTextLengthValid(false);
	}, [textLengthValid]);

	// 하이라이팅 임시삭제
	useEffect(() => {
		if (
			selectedFeatureItem &&
			selectedFeatureItem.searchs &&
			selectedFeatureItem.searchs.length > 0
		) {
			setEditorState(
				EditorState.set(editorState, {
					decorator: detailDecoratorHightLight(
						selectedFeatureItem.searchs.map((x: any) => x),
						selectedFeatureItem.color,
					),
				}),
			);
		}
	}, [selectedFeatureItem]);

	return (
		<>
			<label
				htmlFor="editor_title"
				className={`${titleValue.length >= 100 ? '' : ' mb-5'}`}
			>
				<div className="flex items-center h-full">
					<input
						type="span"
						value={titleValue}
						onChange={(e) => setTitleValue(e.target.value)}
						className={cls(
							'p-5 w-full h-[55px] font-bold text-[20px] transition-all border-2 border-dashed border-editor',
							'outline-none focus:border-main-blue/80 focus:border-dashed focus:border-2',
						)}
						// style={{ width: editorLocation.width }}
						placeholder={titleValue !== '' ? '' : '제목을 입력해주세요.'}
						maxLength={100}
						ref={editorTitleFocusRef}
						disabled
					/>
				</div>
			</label>
			{titleValue.length >= 100 ? (
				<span className="text-red-600 font-bold p-1 mb-3 text-[16px]">
					제목은 30자를 넘을 수 없습니다.
				</span>
			) : null}

			<div
				role="textbox"
				tabIndex={0}
				className={`p-5 pr-0 flex flex-col h-full outline-none outline-1 outline-editor transition-all text-[18px] 2xl:text-[20px] leading-6 hover:cursor-text overflow-auto${
					isFocused ? ' outline-main-blue/80 outline-2' : ''
				}`}
				onKeyUp={() => null}
				ref={editorLocateRef}
			>
				<Editor
					editorState={editorState}
					onChange={setEditorState}
					stripPastedStyles
					placeholder="글을 작성해주세요."
					onFocus={() => handleFocusing()}
					onBlur={() => handleFocusing()}
				/>
			</div>
			<div className="flex justify-between mt-2 mb-5 text-sm">
				<span>{newText.length} / 3000</span>
				{/* 임시삭제 */}
				{/* 
        <div>
					<UpdateWithCancel
						okText="수정"
						okClass="w-20 border p-2 border-main-blue text-main-blue hover:border-main-blue/100 hover:bg-main-blue/100 hover:text-white/100 transition-all rounded-lg mr-3"
						cancelText="취소"
						cancelClass="w-20 border p-2 border-red-500 text-red-500  hover:bg-red-500/100 hover:border-red-500/100 hover:text-white rounded-lg transition-all"
						handleOk={() => handleOk()}
						handleCancel={() => handleCancel()}
					/>
				</div> */}
			</div>
		</>
	);
}

DetailDraft.defaultProps = {
	editorTitleFocusRef: () => null,
};
