import React from 'react';
import { Button } from 'antd';

import 'antd/lib/button/style/index.css';

function TableButton({
	clickHandlers,
	className,
	children,
	isDelete = false,
}: {
	clickHandlers: () => any;
	children: any;
	className?: string;
	isDelete?: boolean;
}) {
	return (
		<>
			{isDelete ? (
				<Button
					type="primary"
					ghost
					danger
					onClick={clickHandlers}
					className={className}
				>
					{children}
				</Button>
			) : (
				<Button
					type="primary"
					ghost
					onClick={clickHandlers}
					className={className}
				>
					{children}
				</Button>
			)}
		</>
	);
}

export default React.memo(TableButton);

TableButton.defaultProps = {
	isDelete: false,
	className: '',
};
