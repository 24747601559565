import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface OffSet {
	width: number;
	height: number;
}

const initialState = {
	offset: { width: 0, height: 0 },
	score: {
		listVisible: false,
		detailVisible: false,
	},
	features: {},
	predicts: {
		과제: 0,
		전달: 0,
		언어: 0,
	},
	personScores: {
		과제: 0,
		전달: 0,
		언어: 0,
	},
	lqInfos: {},
	selectedFeatureItem: {},
	loading: {
		isLoadingAll: false,
		isLoadingCompleted: false,
		isAvgScoreCompleted: false,
		isPredictCompleted: false,
		isLqInfoCompleted: false,
	},
};

export const detailSlice = createSlice({
	name: 'detail',
	initialState,
	reducers: {
		setViewPortXY: (state, action: PayloadAction<OffSet>) => {
			state.offset = { ...action.payload };
		},
		toggleScoreListVisible: (state) => {
			state.score.listVisible = !state.score.listVisible;
		},
		toggleScoreDetailVisible: (state) => {
			state.score.detailVisible = !state.score.detailVisible;
		},
		setScoreListVisible: (state, action: PayloadAction<boolean>) => {
			state.score.listVisible = action.payload;
		},
		setScoreDetailVisible: (state, action: PayloadAction<boolean>) => {
			state.score.detailVisible = action.payload;
		},
		setFeatures: (state, action: PayloadAction<[]>) => {
			state.features = action.payload;
		},
		setPredicts: (state, action: PayloadAction<any>) => {
			state.predicts = action.payload;
		},
		setPersonScores: (state, action: PayloadAction<any>) => {
			state.personScores = action.payload;
		},
		setLqInfos: (state, action: PayloadAction<any>) => {
			state.lqInfos = action.payload;
		},
		onSelectedFeatureItem: (state, action: PayloadAction<any>) => {
			state.selectedFeatureItem = action.payload;
		},
		setInit: (state) => {
			Object.assign(state, initialState);
		},
		setIsLoadingAll: (state, action: PayloadAction<boolean>) => {
			state.loading.isLoadingAll = action.payload;
		},
		setIsLoadingCompleted: (state, action: PayloadAction<boolean>) => {
			state.loading.isLoadingCompleted = action.payload;
		},
		setIsLqInfoCompleted: (state, action: PayloadAction<boolean>) => {
			state.loading.isLqInfoCompleted = action.payload;
		},
		setIsPredictCompleted: (state, action: PayloadAction<boolean>) => {
			state.loading.isPredictCompleted = action.payload;
		},
		setIsAvgScoreCompleted: (state, action: PayloadAction<boolean>) => {
			state.loading.isAvgScoreCompleted = action.payload;
		},
	},
});

export const {
	setViewPortXY,
	toggleScoreListVisible,
	toggleScoreDetailVisible,
	setScoreListVisible,
	setScoreDetailVisible,
	setFeatures,
	setPredicts,
	setPersonScores,
	setLqInfos,
	onSelectedFeatureItem,
	setInit,
	setIsLoadingAll,
	setIsLoadingCompleted,
	setIsLqInfoCompleted,
	setIsPredictCompleted,
	setIsAvgScoreCompleted,
} = detailSlice.actions;
export default detailSlice.reducer;
