import React, { useState, useRef, useEffect, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios, { AxiosPromise, AxiosResponse } from 'axios';
/** components */
import { DetailDraft } from '@/components';
import { EditorState, ContentState } from 'draft-js';
import Swal from 'sweetalert2';
import {
	setFeatures,
	setPredicts,
	setLqInfos,
	setPersonScores,
	setIsLoadingCompleted,
	setIsLqInfoCompleted,
	setIsPredictCompleted,
} from '@/app/reducer/detailpage/detailSlice';
import { useAppDispatch } from '@/app/hooks';
/** utils */
import { USER_TOKEN, getCookie, BASE_URL, POSTS } from '@/utils';

/** types */
import type { PostData } from '@/types';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useRedux } from '@/hooks';

interface EditorLocationProps {
	width: number;
	height: number;
}

interface PreprocessFeatureInput {
	qid: number;
	text: string;
}

interface PromiseOutput {
	results: [];
	status: string;
}

function getPreprocessFeature({
	queryKey,
}: any): Promise<PromiseOutput> | null {
	const [api, input] = queryKey;
	if (!input || !input.qid || !input.text) return null;
	return axios({ url: api, method: 'post', data: input })
		.then((res) => res.data)
		.catch((err) => console.error(err));
}

function getPredict({ queryKey }: any): Promise<PromiseOutput> | null {
	const [api, input] = queryKey;
	if (!input || !input.qid || !input.text) return null;
	return axios({
		url: `${api}/${input.qid}`,
		method: 'post',
		data: { text: input.text },
	})
		.then((res) => res.data)
		.catch((err) => console.error(err));
}

function getLqInfo({ queryKey }: any): Promise<PromiseOutput> | null {
	const [api, input] = queryKey;

	if (!input || !input.text) return null;
	return axios({
		url: `${api}`,
		method: 'post',
		data: { text: input.text },
	})
		.then((res) => res.data)
		.catch((err) => console.error(err));
}

export default function DetailDraftContainer() {
	const [fetchPostData, setFetchPostData] = useState<PostData | undefined>();
	const [editorState, setEditorState] = useState(() =>
		EditorState.createEmpty(),
	);
	const [editorLocation, setEditorLocation] = useState<EditorLocationProps>(
		{} as EditorLocationProps,
	);

	const [titleValue, setTitleValue] = useState<string>('');
	const [newText, setNewText] = useState<string>('');
	const [isFocused, setIsFocused] = useState<boolean>(false);
	const editorLocateRef = useRef<HTMLDivElement>(null);
	const editorTitleFocusRef = useRef<HTMLInputElement>(null);
	const { id, postId } = useParams();
	const navigate = useNavigate();
	const { isAvgScoreLoading } = useRedux('detail');
	const dispatch = useDispatch();

	const { CancelToken } = axios;
	const source = CancelToken.source();

	const [preprocessFeatureInput, setPreProcessFeatureInput] =
		useState<PreprocessFeatureInput>();
	const [preprocessPredictInput, setPreprocessPredictInput] =
		useState<PreprocessFeatureInput>();
	// const [preprocessLQInput, setPreprocessLQInput] =
	// 	useState<PreprocessFeatureInput>();

	const {
		data: preProcessData,
		isLoading,
		remove: featureRemove,
	} = useQuery({
		queryKey: ['/api/preprocess/feature', preprocessFeatureInput],
		queryFn: getPreprocessFeature,
		refetchOnWindowFocus: false,
	});

	const {
		data: prePredictData,
		isLoading: isPredictLoading,
		remove: predictRemove,
	} = useQuery({
		queryKey: ['/api/predict', preprocessPredictInput],
		queryFn: getPredict,
		refetchOnWindowFocus: false,
	});

	const {
		data: preLqInfoData,
		isLoading: isLqInfoLoading,
		remove: lqRemove,
	} = useQuery({
		queryKey: ['/api/preprocess/lq', preprocessFeatureInput],
		queryFn: getLqInfo,
		refetchOnWindowFocus: false,
	});

	/** Fetching Data */
	useEffect(() => {
		const cookieHash = getCookie(USER_TOKEN);

		(async () => {
			try {
				const res = await axios.get(`/${BASE_URL}/${POSTS}/${postId}`, {
					headers: {
						Authorization: cookieHash,
					},
					cancelToken: source.token,
				});
				if (res.status === 200) {
					const { data } = res.data;

					if (!data.id) {
						alert('존재하지 않는 데이터입니다! 이전 페이지로 돌아갑니다.');
						navigate(-1);
						return;
					}

					setFetchPostData(data);
					setTitleValue(`응시자 ID: ${data.tester_id}`);

					if (data && !data.auto_score) {
						setPreprocessPredictInput({
							qid: data.question_id,
							text: data.content,
						});
					} else if (data && data.auto_score) {
						dispatch(setPredicts(JSON.parse(data.auto_score)));
						dispatch(setIsPredictCompleted(true));
					}

					setPreProcessFeatureInput({
						qid: data.question_id,
						text: data.content,
					});

					dispatch(setPersonScores(JSON.parse(data.person_score)));
				}
			} catch (e) {
				if (axios.isCancel(e)) {
					console.error('Request', e);
				}
			}
		})();
	}, []);

	useEffect(() => {
		if (!isLoading && preProcessData?.results) {
			dispatch(setFeatures(preProcessData.results));
			dispatch(setIsLoadingCompleted(true));
		} else dispatch(setIsLoadingCompleted(false));
	}, [isLoading, preProcessData]);

	useEffect(() => {
		if (!isPredictLoading && prePredictData?.results) {
			dispatch(setPredicts(prePredictData.results));
			dispatch(setIsPredictCompleted(true));
		} else dispatch(setIsPredictCompleted(false));
	}, [isPredictLoading, prePredictData]);

	useEffect(() => {
		if (!isLqInfoLoading && preLqInfoData?.results) {
			dispatch(setLqInfos(preLqInfoData.results));
			dispatch(setIsLqInfoCompleted(true));
		} else dispatch(setIsLqInfoCompleted(false));
	}, [isLqInfoLoading, preLqInfoData]);

	/** Title input 박스로 포커싱 */
	useEffect(() => {
		editorTitleFocusRef.current?.focus();
	}, []);

	function handleFocusing() {
		setIsFocused((prev) => !prev);
	}

	async function handleOk() {
		const cookieHash = getCookie(USER_TOKEN);

		try {
			const res: AxiosResponse = await axios.patch(
				`/${BASE_URL}/${POSTS}/${postId}`,
				{
					title: titleValue,
					content: newText,
					question_id: id,
				},
				{
					headers: {
						Authorization: cookieHash,
					},
					cancelToken: source.token,
				},
			);

			if (res.data.status === 'success') {
				navigate(-1);
			}
		} catch (e) {
			if (axios.isCancel(e)) {
				console.error('Request', e);
				navigate(-1);
			}
		}
	}

	function handleCancel() {
		Swal.fire({
			icon: 'warning',
			html: `현재 페이지를 닫으시겠습니까?<br/>저장되지 않은 정보는 모두 삭제됩니다.`,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: '예',
			cancelButtonText: '아니오',
			heightAuto: false,
		}).then((result) => {
			if (result.isConfirmed) {
				navigate(-1);
			}
		});
	}
	return (
		<DetailDraft
			fetchPostData={fetchPostData}
			editor={{ editorState, setEditorState }}
			editorLocation={editorLocation}
			editorLocateRef={editorLocateRef}
			title={{ titleValue, setTitleValue }}
			isFocused={isFocused}
			editorTitleFocusRef={editorTitleFocusRef}
			text={{ newText, setNewText }}
			handleFocusing={() => handleFocusing()}
			handleOk={() => handleOk()}
			handleCancel={() => handleCancel()}
		/>
	);
}
