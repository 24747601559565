import React, { ReactElement, useState, useEffect, useRef } from 'react';

/** router */
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

/** pages */
import {
	MainPage,
	LoginPage,
	NotFound,
	DetailPage,
	QuestionPage,
	NewPostPage,
} from '@/pages';
import axios from 'axios';

/**  components */
import { DataModal, Navigation } from '@/components';

// import scrollbar from 'smooth-scrollbar';

/** utils */
import {
	USER_TOKEN,
	getCookie,
	deleteCookie,
	BASE_URL,
	QUESTION_LIST,
} from '@/utils';
import { useDispatch } from 'react-redux';
import { useRedux } from './hooks';
import { setIsLogin } from './app/reducer/login/loginSlice';
import { HOMEPAGE } from './utils/url';

function App(): ReactElement {
	const { isLogin } = useRedux('login');
	const { showDataModal } = useRedux('question');
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	// const [isLogin, setIsLogin] = useState<boolean>(false);
	const [notFound, setNotFound] = useState<boolean>(false);
	const [detail, setDetail] = useState<boolean>(false);
	const [newPost, setNewPost] = useState<boolean>(false);

	/** Token 만료 확인 */
	useEffect(() => {
		const cookieHash = getCookie(USER_TOKEN);
		/** axios 요청 취소 */
		const { CancelToken } = axios;
		const source = CancelToken.source();

		(async () => {
			try {
				const res = await axios.get(`/${BASE_URL}/auth/valid`, {
					headers: { Authorization: cookieHash },
					cancelToken: source.token,
				});
				if (res.status === 200) {
					// setIsLogin(true);
					dispatch(setIsLogin(true));
				}
			} catch (e: any) {
				if (axios.isCancel(e)) {
					console.error('Request', e.message);
				} else if (window.location.pathname !== '/') {
					// 에러 핸들링, Authorization 실패시 로그인페이지로 이동
					alert(
						`${
							cookieHash
								? '잘못된 경로입니다.'
								: '로그인 기간이 만료되었습니다.'
						} 로그인 페이지로 이동합니다.`,
					);

					deleteCookie(USER_TOKEN);
					dispatch(setIsLogin(false));
					navigate('/', { replace: true });
				}
			}
		})();

		return () => {
			window.scrollTo(0, 0);
			source.cancel();
		};
	}, [location]);

	// useEffect(() => {
	// 	const $DOM = document.querySelector('#smooth-scroll') as HTMLElement;
	// 	scrollbar.init($DOM, options);

	// 	return () => scrollbar.destroy($DOM);
	// }, []);

	return (
		<div className="w-full h-full mx-auto">
			{/* <div id="smooth-scroll">
				<div className="h-[700px] w-full m-0 p-0"> */}
			{notFound || newPost || !isLogin ? null : <Navigation />}
			<Routes>
				<Route path="/" element={<LoginPage />} />
				<Route path="/question_list" element={<QuestionPage />} />
				<Route
					path="/question_list/:id/write"
					element={<NewPostPage setNewPost={setNewPost} />}
				/>
				<Route path="/question_list/:id/posts" element={<MainPage />} />
				<Route
					path="question_list/:id/posts/:postId"
					element={<DetailPage setDetail={setDetail} />}
				/>
				<Route
					path="/*"
					element={<NotFound notFound={notFound} setNotFound={setNotFound} />}
				/>
			</Routes>
			{showDataModal && <DataModal />}
		</div>
	);
}

export default App;
