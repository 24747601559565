import React, { useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';

/** components */
import { Footer, LinkBtn, PostInfo, Spinner } from '@/components';

/** Container */
import {
	PostHeaderContainer,
	PostListContainer,
	PaginationContainer,
} from '@/containers';

/** utils */
import { QUESTION_LIST } from '@/utils';
import { useRedux } from '@/hooks';

export default function MainPage() {
	const [scroll, setScroll] = useState();

	const { fetchPostDataArr, isLoadingPostData } = useRedux('post');
	const { id } = useParams();
	const postStart = useRef<HTMLDivElement>(null);

	return (
		<>
			<h1 className="sr-only">메인페이지</h1>
			<section className="min-h-[calc(100vh-5rem)] responsive-screen mx-auto">
				<h2 className="sr-only">게시물</h2>

				<div className="mt-32 mb-6 flex items-center justify-center items-center relative">
					<PostHeaderContainer />
				</div>

				<div className="text-[16px] mx-auto w-full mb-5 sticky top-[110px] z-10 bg-white shadow-md relative mb-10">
					<PostInfo />
					<div className="flex justify-center items-center fixed left-0 top-[110px] h-[100px] w-[5vw]">
						<LinkBtn url={`/${QUESTION_LIST}`} fontSize="calc(4vw)" endScroll />
					</div>
				</div>

				<div className={`responsive-screen w-full mx-auto `} ref={postStart}>
					<PostListContainer />
					{fetchPostDataArr.length > 0 ? (
						<div className="px-5 py-10 mx-auto">
							<PaginationContainer />
						</div>
					) : null}
				</div>

				<div className="relative">
					{!isLoadingPostData && <Spinner text="Loading.." />}
					{isLoadingPostData && fetchPostDataArr.length <= 0 ? (
						<div className="flex justify-center items-center">
							<Link
								className="border-none w-[400px] h-[250px] flex flex-col justify-center items-center hover:border-main-blue hover:text-main-blue"
								to={`/${QUESTION_LIST}/${id}/write`}
							>
								<span className="text-[30px]">+</span>
								<span>새 글을 추가해주세요.</span>
							</Link>
						</div>
					) : null}
				</div>
			</section>
			<Footer className="bg-[#444444] h-28 z-12" />
		</>
	);
}
