import React, { useState, useEffect } from 'react';

import Chart, { ColumnLineChartOptions } from '@toast-ui/chart';
import { ScoreData } from '@/types';
import { DataType } from '@/utils';

interface Props {
	selectedStatisticsType: string;
	scores: Array<any>;
	idx: number | string;
	title?: string;
	width?: number;
	height?: number;
}

export default function ModalColumnLineChart({
	selectedStatisticsType,
	idx,
	scores,
	title,
	width,
	height,
}: Props) {
	const [chartData, setChartData] = useState<any>({});

	useEffect(() => {
		const categories = [DataType.과제, DataType.전달, DataType.언어];
		const scoreHash = new Map();
		const lenHash = new Map();
		const selectedScore = new Map();
		const selectedLen = new Map();

		scores.forEach((data) => {
			const personData = data.persons;
			const autoData = data.auto;

			categories.forEach((category) => {
				const personSum = personData[category].reduce(
					(prev: number, cur: number) => prev + cur,
					0,
				);
				const autoSum = autoData[category].reduce(
					(prev: number, cur: number) => prev + cur,
					0,
				);
				const personLen = personData[category].length;
				const autoLen = autoData[category].length;

				if (selectedStatisticsType === data.no) {
					selectedScore.set(
						`auto_${category}`,
						(selectedScore.get(`auto_${category}`) || 0) + autoSum,
					);
					selectedScore.set(
						`person_${category}`,
						(selectedScore.get(`person_${category}`) || 0) + personSum,
					);
					selectedLen.set(
						`auto_${category}`,
						(selectedLen.get(`auto_${category}`) || 0) + autoLen,
					);
					selectedLen.set(
						`person_${category}`,
						(selectedLen.get(`person_${category}`) || 0) + personLen,
					);
				}

				scoreHash.set(
					`auto_${category}`,
					(scoreHash.get(`auto_${category}`) || 0) + autoSum,
				);
				scoreHash.set(
					`person_${category}`,
					(scoreHash.get(`person_${category}`) || 0) + personSum,
				);
				lenHash.set(
					`auto_${category}`,
					(lenHash.get(`auto_${category}`) || 0) + autoLen,
				);
				lenHash.set(
					`person_${category}`,
					(lenHash.get(`person_${category}`) || 0) + personLen,
				);
			});
		});

		const data = {
			categories,
			series: {
				column: [
					{
						name: '문항별 기계채점 평균',
						data: categories.map((category) =>
							selectedLen.get(`auto_${category}`) === 0
								? 0
								: Number(
										(
											(selectedScore.get(`auto_${category}`) * 25) /
											selectedLen.get(`auto_${category}`)
										).toFixed(2),
								  ),
						),
					},
					{
						name: '문항별 인간채점 평균',
						data: categories.map((category) =>
							selectedLen.get(`person_${category}`) === 0
								? 0
								: Number(
										(
											(selectedScore.get(`person_${category}`) * 25) /
											selectedLen.get(`person_${category}`)
										).toFixed(2),
								  ),
						),
					},
				],
				line: [
					{
						name: '전체 기계채점 평균',
						data: categories.map((category) =>
							scoreHash.get(`auto_${category}`) === 0
								? 0
								: Number(
										(
											(scoreHash.get(`auto_${category}`) * 25) /
											lenHash.get(`auto_${category}`)
										).toFixed(2),
								  ),
						),
					},
					{
						name: '전체 인간채점 평균',
						data: categories.map((category) =>
							scoreHash.get(`person_${category}`) === 0
								? 0
								: Number(
										(
											(scoreHash.get(`person_${category}`) * 25) /
											lenHash.get(`person_${category}`)
										).toFixed(2),
								  ),
						),
					},
				],
			},
		};

		const options: ColumnLineChartOptions = {
			responsive: { animation: { duration: 300 } },
			tooltip: {
				formatter: (value, tooltipDataInfo) => `${value}점`,
			},
			chart: {
				// title: {
				// 	// text: `${title}`,
				// 	// offsetY: -5,
				// 	// align: 'center',
				// },
				animation: {
					duration: 2000,
				},
				width: 'auto',
				height: 'auto',
			},
			series: {
				selectable: true,
				dataLabels: {
					visible: true,
				},
				line: {
					dataLabels: {
						visible: false,
						offsetY: -10,
					},
				},
			},
			exportMenu: {
				visible: false,
			},
			legend: {
				visible: true,
				align: 'bottom',
				showCheckbox: false,
			},
			xAxis: {
				title: 'Sector',
			},
			yAxis: {
				title: 'Score',
				scale: {
					max: 100,
				},
			},

			theme: {
				chart: {
					fontFamily: 'Verdana',
				},
				series: {
					// colors: ['#1492E5', '#F6A632'],
					column: {
						dataLabels: {
							fontWeight: 500,
						},
					},
					line: {
						dataLabels: {
							fontWeight: 600,
						},
					},
				},
				title: {
					fontSize: 20,
					fontWeight: 400,
					color: 'rgba(0,0,0,0.7)',
				},
				xAxis: {
					title: {
						color: 'rgba(0,0,0,0.5)',
					},
				},
				yAxis: {
					title: {
						color: 'rgba(0,0,0,0.5)',
					},
				},
			},
		};
		const el = document.getElementById(`modalColumnLine_${idx}`);
		if (el === null) return undefined;

		const modalColumnLine = Chart.columnLineChart({ el, data, options });

		return () => {
			modalColumnLine.destroy();
		};
	}, []);

	return (
		<div
			id={`modalColumnLine_${idx}`}
			className={`${idx === 2 ? '' : ' mr-3'}`}
			style={{
				width,
				height,
			}}
		/>
	);
}

ModalColumnLineChart.defaultProps = {
	title: 'default Title',
	width: 100,
	height: 350,
};
