import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Statistics } from '@/types';

const initialState = {
	statistics: {},
	selectedStatisticsNum: 0,
	selectedStatisticsType: '',
	isThreePointAnswer: false,
	// chart: {
	// 	chartModalColumnMaxAxis: 0,
	// },
};

export const chartSlice = createSlice({
	name: 'chart',
	initialState,
	reducers: {
		setStaticsticsToRedux: (state, action: PayloadAction<Statistics>) => {
			state.statistics = action.payload;
		},
		setSelectedStatisticsNum: (state, action: PayloadAction<number>) => {
			state.selectedStatisticsNum = action.payload;
		},
		setSelectedStatisticsType: (state, action: PayloadAction<string>) => {
			state.selectedStatisticsType = action.payload;
		},
		// setChartModalColumnMaxAxis: (state, action: PayloadAction<number>) => {
		// 	state.chart.chartModalColumnMaxAxis = action.payload;
		// },
		setIsThreePointAnswer: (state, action: PayloadAction<boolean>) => {
			state.isThreePointAnswer = action.payload;
		},
	},
});

export const {
	setStaticsticsToRedux,
	setSelectedStatisticsNum,
	setSelectedStatisticsType,
	setIsThreePointAnswer,
	// setChartModalColumnMaxAxis,
} = chartSlice.actions;
export default chartSlice.reducer;
