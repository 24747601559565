import React, { useState, useEffect, Fragment, useRef } from 'react';

/** component */
import { InputBox } from '@/components';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

/** redux-reducer */
import {
	toggleScoreListVisible,
	toggleScoreDetailVisible,
	setIsAvgScoreCompleted,
} from '@/app/reducer/detailpage/detailSlice';
import { setIsThreePointAnswer } from '@/app/reducer/chartSlice';

/** hook */
import { useRedux } from '@/hooks';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { cls, getQuestionInfoFromSession } from '@/utils';

export default function DetailScoreMenu() {
	const { score, predicts, personScores } = useRedux('detail');
	const { listVisible, detailVisible } = score;

	const dispatch = useDispatch();
	const [avgAIScore, setAvgAIScore] = useState<number>();
	const [avgPersonScore, setAvgPersonScore] = useState<number>();

	const questionNumber = useRef<number>(4);

	useEffect(() => {
		if (predicts) {
			const values: number[] = Object.values(predicts);
			const sum = values.reduce((acc, cur) => acc + cur, 0);
			const avg = Math.round(
				(sum / (questionNumber.current * values.length)) * 100,
			);
			setAvgAIScore(avg);
		}
	}, [predicts, questionNumber.current]);

	useEffect(() => {
		if (personScores) {
			const values: number[] = Object.values(personScores);
			const sum = values.reduce((acc, cur) => acc + cur, 0);
			const avg = Math.round(
				(sum / (questionNumber.current * values.length)) * 100,
			);
			setAvgPersonScore(avg);
		}
	}, [personScores, questionNumber.current]);

	useEffect(() => {
		dispatch(setIsAvgScoreCompleted(true));

		return () => {
			dispatch(setIsAvgScoreCompleted(false));
		};
	}, [avgAIScore]);

	useEffect(() => {
		const temp = getQuestionInfoFromSession();
		if (JSON.stringify(temp) === JSON.stringify({})) return undefined;
		const arr = temp.no.split('');

		if (arr[arr.length - 1] === '1' || arr[arr.length - 1] === '2') {
			questionNumber.current = 3;
			dispatch(setIsThreePointAnswer(true));
		} else {
			questionNumber.current = 4;
			dispatch(setIsThreePointAnswer(false));
		}

		return undefined;
	}, [getQuestionInfoFromSession()]);

	return (
		<div className={cls('w-full text-[18px]')}>
			<ul
				className={`flex flex-col justify-center transition-all duration-500 ease-in-out ${
					listVisible
						? 'bg-side-navy text-white rounded-t-lg'
						: 'bg-white border-b-side-navy text-black outline outline-1 outline-editor hover:outline-2 rounded-lg'
				}`}
			>
				<button
					type="button"
					onClick={() => {
						dispatch(toggleScoreListVisible());
						if (listVisible && detailVisible) {
							dispatch(toggleScoreDetailVisible());
						}
					}}
				>
					<li className="flex items-center justify-between  pl-4 pr-2 py-1 h-[74px]">
						<div className="flex justify-center items-center">
							{listVisible ? (
								<MinusCircleOutlined
									className="mr-3 text-main-blue"
									style={{ fontSize: `${detailVisible ? 25 : 30}px` }}
								/>
							) : (
								<PlusCircleOutlined
									className="mr-3 text-main-blue"
									style={{ fontSize: `${detailVisible ? 25 : 30}px` }}
								/>
							)}
							<div className="flex flex-col text-[16px] leading-5">
								<span
									className={`text-start${detailVisible ? '' : ' text-[16px]'}`}
								>
									작문 점수
								</span>
								<span className="text-[12px]">ESSAY SCORE</span>
							</div>
						</div>

						<InputBox
							type="button"
							keyText="기계채점"
							keyClass="font-bold flex flex-col items-center justify-center p-1 text-[14px]"
							valText={avgAIScore ? avgAIScore.toString() : '0'}
							valClass="rounded bg-white text-main-blue font-bold text-[24px] px-3 min-w-[60px]"
						/>
						<InputBox
							type="button"
							keyText="인간채점"
							keyClass="font-bold flex flex-col items-center justify-center p-1 text-[14px]"
							valText={avgAIScore ? '' : '0'}
							valClass="rounded bg-white text-main-orange font-bold text-[24px] px-3 min-w-[60px]"
						/>
					</li>
				</button>
			</ul>

			<ul
				className={`test border border-side-navy text-[15px] transition-all duration-500 ${
					listVisible
						? 'visible h-auto test-fade-in'
						: 'hidden h-[0px] test-fade-out'
				}
				`}
			>
				<li className="flex justify-between items-center p-1 text-[18px] border-b-2 text-side-navy">
					<div className="flex w-full justify-end">
						<span className="flex w-full justify-start pl-6 text-[20px]">
							영역
						</span>
					</div>
					<div className="flex">
						<span
							className={cls(
								'whitespace-nowrap text-[17px] text-main-blue mr-1',
								'w-[70px]',
							)}
						>
							기계채점
						</span>
						<span
							className={cls(
								'w-[70px]',
								'whitespace-nowrap text-[17px] text-main-orange ',
							)}
						>
							인간채점
						</span>
					</div>
				</li>
				{predicts &&
					Object.keys(predicts).map((key: any, idx) => (
						<Fragment key={key}>
							<li
								className={cls(
									'flex justify-between items-center pl-4 pr-2 mt-2',
									`${idx === 0 ? 'pt-0' : ''}`,
								)}
							>
								<span
									className={cls(
										`${detailVisible ? 'text-[18px]' : 'text-[20px]'}`,
									)}
								>
									{key} 점수
								</span>
								<div className="flex justify-center items-center">
									<span
										className={cls(
											'w-[70px] text-[18px] mr-1',
											'flex justify-center items-center text-main-blue font-bold border p-2 rounded-lg bg-[#f3f4f6]',
										)}
									>{`${predicts[key]} / ${questionNumber.current}`}</span>
									<span
										className={cls(
											'w-[70px] text-[18px]',
											'flex justify-center items-center text-main-orange font-bold border p-2 rounded-lg bg-[#f3f4f6]',
										)}
									>
										{`${personScores ? personScores[key] : '0'} / ${
											questionNumber.current
										}`}
									</span>
								</div>
							</li>
						</Fragment>
					))}
				<li
					key="total-score"
					className="flex justify-between items-center pl-4 pr-2 mt-2 text-[20px]"
				>
					<span
						className={cls(`${detailVisible ? 'text-[18px]' : 'text-[20px]'}`)}
					>
						총점 (100)
					</span>

					<div className="flex justify-center items-center text-[20px]">
						<span
							className={cls(
								'w-[70px] mr-1',
								'flex justify-center items-center',
								'text-main-blue font-bold border p-2 rounded-lg bg-[#f3f4f6]',
							)}
						>
							{`${avgAIScore}`}
						</span>
						<span
							className={cls(
								'flex justify-center items-center',
								'w-[70px]',
								'text-main-orange font-bold border p-2 rounded-lg bg-[#f3f4f6]',
							)}
						>
							{`${avgPersonScore}`}
						</span>
					</div>
				</li>
				{listVisible && (
					<li className="flex justify-center items-center p-3">
						<input
							className="rounded-full border px-4 py-2 hover:cursor-pointer hover:bg-blue-400 hover:text-white hover:border-blue-400"
							type="button"
							value="목록보기"
							onClick={() => dispatch(toggleScoreDetailVisible())}
						/>
					</li>
				)}
			</ul>
		</div>
	);
}
