import React, { useEffect, useLayoutEffect } from 'react';

/** axios */
import axios from 'axios';

/** router */
import { useNavigate } from 'react-router-dom';

/** components */
import { useForm, SubmitHandler } from 'react-hook-form';
import Swal from 'sweetalert2';

/** utils */
import {
	USER_TOKEN,
	setCookie,
	getCookie,
	POSTS,
	BASE_URL,
	AUTH_LOGIN,
	deleteCookie,
} from '@/utils';

/** types */
import type { AuthUserInput } from '@/types';
import type { AxiosResponse } from 'axios';
import type { SetStateAction, Dispatch } from 'react';
import { HOMEPAGE, QUESTION_LIST } from '@/utils/url';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import { useRedux } from '@/hooks';
import { useDispatch } from 'react-redux';
import { setIsLogin } from '@/app/reducer/login/loginSlice';

// interface Props {
// 	isLogin: boolean;
// 	setIsLogin: Dispatch<SetStateAction<boolean>>;
// }

export default function LoginPage() {
	const navigate = useNavigate();
	const { isLogin } = useRedux('login');
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors, isSubmitSuccessful },
	} = useForm<AuthUserInput>({
		defaultValues: {
			email: '',
			password: '',
		},
	});

	useLayoutEffect(() => {
		if (isLogin) {
			navigate(`/${HOMEPAGE}`, { replace: true });
		}
	}, [isLogin]);

	const onSubmit: SubmitHandler<AuthUserInput> = async (data) => {
		console.log('logging in..');
		try {
			const payload: AuthUserInput = {
				email: data.email,
				password: data.password,
			};

			const res: AxiosResponse = await axios.post(
				`/${BASE_URL}/${AUTH_LOGIN}`,
				payload,
			);
			if (res.status === 200) {
				console.log('login success!');

				setCookie(USER_TOKEN, res.data.Authorization);
				dispatch(setIsLogin(true));
				navigate(`/${HOMEPAGE}`, { replace: true });
			}
		} catch (e: unknown) {
			console.log(e);
			Swal.fire(`Error`, `ID와 비밀번호를 확인하십시오.`, 'error');
		}
	};

	useEffect(() => {
		if (isSubmitSuccessful) {
			reset(
				{
					email: '',
					password: '',
				},
				{ keepDefaultValues: true, keepIsValid: false },
			);
		}
	}, [isSubmitSuccessful, reset]);

	return (
		<section className="m-0 h-screen w-full flex flex-col justify-center items-center ">
			<fieldset className="mx-auto">
				<legend className="sr-only">login-form</legend>
				<article className="flex justify-center items-center">
					<img className="w-68 h-32" src="/sejong-logo.png" alt="logo" />
					<img
						className="w-36 h-12 mx-auto text-black mr-16"
						src="/natmal-logo-white.png"
						alt="logo"
					/>
				</article>
				<form
					className="login"
					onSubmit={handleSubmit(onSubmit)}
					style={{ width: '450px' }}
				>
					<label htmlFor="id">
						<p className="login__label mt-10">아이디</p>
						<input
							className="login__form h-10 p-3"
							{...register('email', { required: '아이디를 입력해주세요.' })}
							placeholder="아이디를 입력해주세요."
						/>
					</label>
					<p className="login__valid-text">{errors.email?.message}</p>

					<label htmlFor="password">
						<p className="login__label mt-3">비밀번호</p>
						<input
							className="login__form h-10 p-3"
							type="password"
							{...register('password', {
								required: '비밀번호를 입력해주세요.',
								minLength: {
									value: 1,
									message: '최소 1글자 이상입니다.',
								},
							})}
							placeholder="비밀번호를 입력해주세요."
						/>
					</label>
					<p className="login__valid-text">{errors.password?.message}</p>

					<input
						className="login__btn mt-8 h-16 hover:cursor-pointer hover:opacity-80"
						type="submit"
						value="로그인"
					/>
				</form>
			</fieldset>
		</section>
	);
}
