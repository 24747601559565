import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState = {
	test: false,
	loading: {
		isMainLoaded: false,
	},
};

export const mainSlice = createSlice({
	name: 'main',
	initialState,
	reducers: {
		toggleIsMainLoaded: (state) => {
			state.loading.isMainLoaded = !state.loading.isMainLoaded;
		},
		setIsMainLoaded: (state, action: PayloadAction<boolean>) => {
			state.loading.isMainLoaded = action.payload;
		},
	},
});

export const { toggleIsMainLoaded, setIsMainLoaded } = mainSlice.actions;
export default mainSlice.reducer;
