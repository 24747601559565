import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

const modalRoot = document.querySelector('#modal-root') as HTMLElement;

interface ModalProps {
	children?: ReactNode;
}

const Modal = ({ children }: ModalProps) =>
	// const el = useRef(document.createElement('div'));
	// useEffect(() => {
	// 	// Use this in case CRA throws an error about react-hooks/exhaustive-deps
	// 	const { current } = el;

	// 	// We assume `modalRoot` exists with '!'
	// 	modalRoot!.appendChild(current);
	// 	return () => {
	// 		modalRoot!.removeChild(current);
	// 	};
	// }, []);

	createPortal(children, modalRoot);
export default Modal;
