import React, { useEffect, SetStateAction, Dispatch } from 'react';

// type
import { Statistics } from '@/types';

type UseKeyEscapeProps = {
	setShowChartModal: Dispatch<React.SetStateAction<boolean>>;
};

export default function useKeyEscape({ setShowChartModal }: UseKeyEscapeProps) {
	// modal닫기위한 key 이벤트
	useEffect(() => {
		const data = (e: KeyboardEvent) => {
			if (e.key !== 'Escape') return;
			if (e.key === 'Escape') {
				setShowChartModal(false);
			}
		};
		window.addEventListener('keyup', data);

		return () => window.removeEventListener('keyup', data);
	}, []);
	return null;
}
