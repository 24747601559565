import React, { Dispatch, SetStateAction } from 'react';

import { Button } from 'antd';
import 'antd/lib/button/style/index.css';

export default function CloseBtn({
	setShowModal,
}: {
	setShowModal: Dispatch<SetStateAction<boolean>>;
}) {
	return (
		<Button
			size="large"
			type="primary"
			danger
			onClick={() => setShowModal(false)}
		>
			X
		</Button>
	);
}
