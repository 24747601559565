import React from 'react';

/** component */
import { ActionButton } from '@/components/atomic';

/** icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used

/** types */
import { HandlerFunc, PaginationState } from '@/types';

interface PaginationProps {
	page: PaginationState;
	handlePrev?: HandlerFunc;
	handlePage?: HandlerFunc;
	handleNext?: HandlerFunc;
}

export default function Pagination({
	page,
	handlePrev,
	handlePage,
	handleNext,
}: PaginationProps) {
	const { currentIdx, totalPage, currentPage } = page;

	function renderPagination() {
		const result = [];

		let lastPage = 10 * currentIdx + 10;

		lastPage = totalPage > lastPage ? lastPage : totalPage;
		for (let i = 10 * currentIdx + 1; i <= lastPage; i += 1) {
			result.push(
				<ActionButton
					key={i}
					className={`w-7 h-7 p-3 flex justify-center items-center hover:border hover:border-main-blue hover:text-main-blue${
						currentPage === i ? ' text-main-blue font-bold' : ''
					}`}
					onClick={handlePage}
				>
					<span>{i}</span>
				</ActionButton>,
			);
		}
		return result;
	}

	return (
		<section className="flex justify-center items-center">
			<ActionButton
				className={`border w-7 h-7 flex justify-center items-center ${
					page.currentIdx === 0 ? 'opacity-20' : ''
				}`}
				disabled={page.currentIdx === 0}
				onClick={handlePrev}
			>
				<FontAwesomeIcon icon={solid('angle-left')} />
			</ActionButton>

			{renderPagination()}

			<ActionButton
				className={`border w-7 h-7 flex justify-center items-center ${
					page.currentIdx * 10 + 10 >= page.totalPage ? 'opacity-20' : ''
				}`}
				disabled={page.currentIdx * 10 + 10 >= page.totalPage}
				onClick={handleNext}
			>
				<FontAwesomeIcon icon={solid('angle-right')} />
			</ActionButton>
		</section>
	);
}

Pagination.defaultProps = {
	handlePrev: () => console.log('prev'),
	handlePage: () => console.log('select page'),
	handleNext: () => console.log('next'),
};
