import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const acceptableFileName = ['xlsx', 'xls'];
export const tableHeader = [
	'아이디',
	'작문',
	'채점자1-과제',
	'채점자1-전달',
	'채점자1-언어',
	'채점자2-과제',
	'채점자2-전달',
	'채점자2-언어',
];

export const downloadTableHeader = [
	'아이디',
	'작문',
	'기계-과제평균',
	'기계-전달평균',
	'기계-언어평균',
	'인간-과제평균',
	'인간-전달평균',
	'인간-언어평균',
];

export const excelSample = [
	{
		id: 'ska-10',
		content: '철수가 밥을 먹는다.',
		s1_1: 0,
		s1_2: 0,
		s1_3: 0,
		s2_1: 0,
		s2_2: 0,
		s2_3: 0,
	},
];

export const excelFileType =
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

export const excelFileExtension = '.xlsx';

/**
 *
 * @param excelFileName 엑셀 파일 이름을 입력받는다.
 */
export function excelSampleDownload(excelFileName = 'excel_sample') {
	const ws = XLSX.utils.aoa_to_sheet([tableHeader]);
	excelSample.map((data: any) => {
		XLSX.utils.sheet_add_aoa(
			ws,
			[
				[
					data.id,
					data.content,
					data.s1_1,
					data.s1_2,
					data.s1_3,
					data.s2_1,
					data.s2_2,
					data.s2_3,
				],
			],
			{
				origin: -1,
			},
		);
		ws['!cols'] = [
			{ wpx: 40 },
			{ wpx: 400 },
			{ wpx: 80 },
			{ wpx: 80 },
			{ wpx: 80 },
			{ wpx: 80 },
			{ wpx: 80 },
			{ wpx: 80 },
		];

		return false;
	});
	const wb: any = { Sheets: { Sheet1: ws }, SheetNames: ['Sheet1'] };
	const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
	const excelFile = new Blob([excelButter], { type: excelFileType });

	// 파일 다운로드
	saveAs(excelFile, excelFileName + excelFileExtension);
}

/**
 * @todo excel
 * @param mode 'sample' | 'all'
 * @param excelData
 * @param excelFileName
 */
export function excelAllDownload(mode: string, excelData: any, no = '') {
	const ws = XLSX.utils.aoa_to_sheet([downloadTableHeader]);
	const temp = [excelSample];
	const excelFileName = `answer_list${no !== '' ? `(${no})` : ''}`;

	excelData.map((data: any) => {
		const auto = JSON.parse(data.auto_score);
		const person = JSON.parse(data.person_score);

		XLSX.utils.sheet_add_aoa(
			ws,
			[
				[
					data.tester_id,
					data.content,
					auto.과제 || 0,
					auto.전달 || 0,
					auto.언어 || 0,
					person.과제 || 0,
					person.전달 || 0,
					person.언어 || 0,
				],
			],
			{
				origin: -1,
			},
		);
		ws['!cols'] = [
			{ wpx: 40 },
			{ wpx: 400 },
			{ wpx: 70 },
			{ wpx: 70 },
			{ wpx: 70 },
			{ wpx: 70 },
			{ wpx: 70 },
			{ wpx: 70 },
		];

		return false;
	});
	const wb: any = { Sheets: { Sheet1: ws }, SheetNames: ['Sheet1'] };
	const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
	const excelFile = new Blob([excelButter], { type: excelFileType });

	// 파일 다운로드
	saveAs(excelFile, excelFileName + excelFileExtension);
}
