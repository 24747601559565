/* eslint-disable camelcase */
import { BASE_URL, getCookie, QUESTIONS, USER_TOKEN } from '@/utils';
import axios from 'axios';

const getQuestions = async () => {
	const cookieHash = getCookie(USER_TOKEN);
	/** axios 요청 취소 */
	const { CancelToken } = axios;
	const source = CancelToken.source();
	try {
		const res = await axios.get(`/${BASE_URL}/${QUESTIONS}/`, {
			headers: { Authorization: cookieHash },
			cancelToken: source.token,
		});

		if (res.status === 201) {
			const { results } = res.data.data;

			const {
				acc_all,
				acc_by_nos,
				datas_all,
				datas_by_no,
				scores_by_no,
				scores,
				mat,
			} = res.data.data;

			return res.data.data;
		}
	} catch (e: any) {
		// 에러 핸들링, Authorization 실패시 로그인페이지로 이동
		if (axios.isCancel(e)) {
			console.error('Request', e.message);
		}
		return null;
	}
	return null;
};

export { getQuestions };
