/* eslint-disable camelcase */
import React, {
	useState,
	SetStateAction,
	Dispatch,
	useLayoutEffect,
	useEffect,
} from 'react';
import axios from 'axios';

// components
import { Portal, CloseBtn } from '@/components';
import Swal from 'sweetalert2';

// hook
import { useModal } from '@/hooks';

// css
import './modal.scss';
import 'antd/lib/button/style/index.css';

// type */
import type { CustomError, QuestionModalData, QuestionTypeData } from '@/types';
import {
	BASE_URL,
	QUESTIONS,
	Q_TYPES,
	WriteMode,
	getCookie,
	USER_TOKEN,
	cls,
} from '@/utils';
import { Button } from 'antd';
import { setMoveToTable } from '@/app/reducer/questionpage/questionSlice';
import { useDispatch } from 'react-redux';

type ModalControlProps = {
	setShowModal: Dispatch<SetStateAction<boolean>>;
	selectQuestionData: QuestionModalData;
	mode: string;
	reFetchDataBoolean: boolean;
	setReFetchDataBoolean: React.Dispatch<SetStateAction<boolean>>;
	setFetchQuestionData: any;
};

export default function QuestionModal({
	setShowModal,
	selectQuestionData,
	mode,
	reFetchDataBoolean,
	setReFetchDataBoolean,
	setFetchQuestionData,
}: ModalControlProps) {
	const [selectOption, setSelectOption] = useState<string>(
		mode === WriteMode.update ? selectQuestionData.type.id.toString() : '',
	);
	const [selectDataList, setSelectDataList] = useState<QuestionModalData>();
	const [prevData, setPrevData] =
		useState<QuestionModalData>(selectQuestionData);
	const [newInputData, setNewInputData] = useState<QuestionModalData>({
		id: 0,
		no: '',
		type_id: 0,
		min_len: 0,
		max_len: 0,
		type: {
			id: 0,
			type: '',
		},
		text: '',
		sample: '',
		answer: '',
		keywords: [],
	});
	const [isUpdateMode, setIsUpdateMode] = useState(mode === WriteMode.update);
	const [questionNumberValid, setQuestionNumberValid] = useState(
		mode === WriteMode.update ? selectQuestionData.no : 0,
	);
	const dispatch = useDispatch();
	useModal();

	/** 변경이 되었을 때, 취소 버튼을 눌러도 alert가 뜨지 않도록 만들기 */

	useLayoutEffect(() => {
		(async () => {
			try {
				const res = await axios.get(`${BASE_URL}/${QUESTIONS}/${Q_TYPES}`);
				const { data } = res.data;
				let questionType = 0;
				if (data.status === 'success') {
					setSelectDataList(data.results);

					questionType = isUpdateMode
						? data.results.filter(
								(elem: QuestionModalData) => +elem.id === prevData.type.id,
						  )[0].id
						: '';

					setSelectOption(questionType.toString());
				}
			} catch (e) {
				console.error(e);
			}
		})();
	}, []);

	// 새로운 내용으로 patch 하기
	function handleOk() {
		const cookieHash = getCookie(USER_TOKEN);

		(async () => {
			try {
				const { no, min_len, max_len, text, sample, answer, keywords } =
					isUpdateMode ? prevData : newInputData;

				const id = isUpdateMode ? prevData.id : null;
				const res = isUpdateMode
					? await axios.patch(
							`/${BASE_URL}/${QUESTIONS}/${id}`,
							{
								no,
								type_id: prevData.type.id,
								min_len,
								max_len,
								text,
								sample,
								answer,
								keywords: typeof keywords === 'string' ? [keywords] : keywords,
							},
							{
								headers: {
									Authorization: cookieHash,
								},
							},
					  )
					: await axios.post(
							`/${BASE_URL}/${QUESTIONS}/`,
							{
								no,
								type_id: newInputData.type.id,
								min_len,
								max_len,
								text,
								sample,
								answer,
								keywords,
							},
							{
								headers: {
									Authorization: cookieHash,
								},
							},
					  );

				const { data } = res.data;
				if (data.status === 'success') {
					setReFetchDataBoolean(true);
					// setFetchQuestionData((prev: any) =>
					// 	[...prev,{ no, min_len, max_len, text, sample, answer, keywords }]
					// );
					setShowModal(false);
					dispatch(setMoveToTable(true));
				}
			} catch (e: unknown) {
				const err = e as CustomError;
				console.error(err);
				Swal.fire({
					title: 'Error',
					text: `문항 번호가 고유한지 확인해주세요.`,
					icon: 'warning',
					heightAuto: false,
				});
			}
		})();
	}

	function handleCancel() {
		Swal.fire({
			icon: 'warning',
			html: `현재 페이지를 닫으시겠습니까?<br/>저장되지 않은 정보는 모두 삭제됩니다.`,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: '예',
			cancelButtonText: '아니오',
			heightAuto: false,
		}).then((result) => {
			if (result.isConfirmed) {
				setShowModal(false);
			}
		});
	}

	return (
		<Portal>
			<h1 className="sr-only">문항 정보 입력</h1>
			<div className="background">
				<div className="modal__question rounded-lg relative">
					<div className="absolute top-0 right-0">
						<CloseBtn setShowModal={setShowModal} />
					</div>
					<form className="w-full h-full p-3 px-5">
						<fieldset className="field-set w-full h-full inline-block">
							<legend className="font-bold flex w-full justify-center p-3">
								문항 정보 입력창
							</legend>
							<ul className="w-full h-full flex flex-col justify-between">
								<li>
									<label
										htmlFor="
                      q_no"
									>
										문항 번호
									</label>
									<input
										className={cls(
											'border',
											`${isUpdateMode ? 'hover:cursor-not-allowed' : ''}
										`,
										)}
										type="text"
										name="q_no"
										id="q_no"
										required
										placeholder="문항 번호를 입력하세요."
										defaultValue={isUpdateMode ? prevData.no.toString() : ''}
										onChange={(e) =>
											isUpdateMode
												? setPrevData((prev) => ({
														...prev,
														no: e.target.value,
												  }))
												: setNewInputData((prev) => ({
														...prev,
														no: e.target.value,
												  }))
										}
										disabled={!!isUpdateMode}
									/>
								</li>

								<li>
									<label htmlFor="minLength maxLength">답안 길이</label>
									<div className="flex w-full">
										<label className="sr-only" htmlFor="minLength">
											최소길이
										</label>
										<input
											className="border"
											id="minLength"
											type="number"
											name="minLength"
											placeholder="최소 길이를 입력해주세요."
											required
											defaultValue={isUpdateMode ? prevData.min_len : ''}
											onChange={(e) =>
												isUpdateMode
													? setPrevData((prev) => ({
															...prev,
															min_len: +e.target.value,
													  }))
													: setNewInputData((prev) => ({
															...prev,
															min_len: +e.target.value,
													  }))
											}
										/>
										<span className="mx-3 flex items-center"> ~ </span>
										<label className="sr-only" htmlFor="maxLength">
											최대길이
										</label>
										<input
											className="border placeholder:p-1"
											id="maxLength"
											type="number"
											name="maxLength"
											placeholder="최대 길이를 입력해주세요."
											required
											defaultValue={isUpdateMode ? prevData.max_len : ''}
											onChange={(e) =>
												isUpdateMode
													? setPrevData((prev) => ({
															...prev,
															max_len: +e.target.value,
													  }))
													: setNewInputData((prev) => ({
															...prev,
															max_len: +e.target.value,
													  }))
											}
										/>
									</div>
								</li>

								<li>
									<label htmlFor="feature-shape">발문형태</label>
									{selectDataList && (
										<select
											className="border w-[400px]"
											name="featureShape"
											id="feature-shape"
											defaultValue={selectOption}
											onChange={(e) => {
												setSelectOption(e.target.value);

												// eslint-disable-next-line no-unused-expressions
												isUpdateMode
													? setPrevData((prev) => ({
															...prev,
															type: {
																...prev.type,
																type: selectDataList.find(
																	(elem: QuestionTypeData) =>
																		elem.id === +e.target.value,
																),
															},
													  }))
													: setNewInputData((prev) => ({
															...prev,
															type: selectDataList.find(
																(elem: QuestionTypeData) =>
																	elem.id === +e.target.value,
															),
													  }));
											}}
										>
											<option value="">선택</option>
											{selectDataList.map((elem: QuestionTypeData) => (
												<option key={elem.id} value={elem.id}>
													{elem.type}
												</option>
											))}
											<option className="relative" value="direct">
												직접입력
											</option>
										</select>
									)}
								</li>
								<li>
									{selectOption === 'direct' && (
										<>
											<label
												className="invisible p-0"
												htmlFor="feature-shape-direct"
											>
												발문형태
											</label>
											<input
												className="border"
												type="text"
												id="feature-shape-direct"
												name="feature-shape"
												placeholder="발문 형태를 직접 입력해주세요."
												required
												defaultValue={isUpdateMode ? prevData.type.type : ''}
												onChange={(e) =>
													isUpdateMode
														? setPrevData((prev) => ({
																...prev,
																type: { ...prev.type, id: +e.target.value },
														  }))
														: setNewInputData((prev) => ({
																...prev,
																type: { ...prev.type, id: +e.target.value },
														  }))
												}
											/>
										</>
									)}
								</li>

								<li>
									<label
										htmlFor="
                      text"
									>
										발문
									</label>
									<input
										className="border"
										type="text"
										name="text"
										id="text"
										required
										placeholder="발문을 입력하세요."
										defaultValue={
											isUpdateMode ? prevData.text : newInputData.text
										}
										onChange={(e) =>
											isUpdateMode
												? setPrevData((prev) => ({
														...prev,
														text: e.target.value,
												  }))
												: setNewInputData((prev) => ({
														...prev,
														text: e.target.value,
												  }))
										}
									/>
								</li>

								{[
									['보기', 'sample'],
									['모범 답안', 'answer'],
									['키워드 선정', 'keywords'],
								].map(([kor, eng]: string[]) => (
									<li key={kor}>
										<label htmlFor={kor}>
											{kor}
											<br />
											(선택)
										</label>
										<textarea
											className="border w-full p-3"
											rows={3}
											name={eng}
											id={eng}
											placeholder={`${kor}을(를) 입력하세요.`}
											style={{ resize: 'none' }}
											defaultValue={
												isUpdateMode ? prevData[eng] : newInputData[eng]
											}
											onChange={(e) => {
												// eslint-disable-next-line no-unused-expressions
												isUpdateMode
													? setPrevData((prev) => ({
															...prev,
															[eng]:
																eng === 'keywords'
																	? e.target.value.trim().split(',')
																	: e.target.value,
													  }))
													: setNewInputData((prev) => ({
															...prev,
															[eng]:
																eng === 'keywords'
																	? e.target.value.trim().split(',')
																	: e.target.value,
													  }));
											}}
										/>
									</li>
								))}

								<li className="flex justify-center items-center p-4">
									<Button
										className="w-20 mr-3"
										type="primary"
										ghost
										onClick={() => handleOk()}
									>
										{isUpdateMode ? '수정' : '추가'}
									</Button>
									<Button
										className="w-20"
										type="ghost"
										danger
										onClick={() => handleCancel()}
									>
										취소
									</Button>
								</li>
							</ul>
						</fieldset>
					</form>
				</div>
			</div>
		</Portal>
	);
}
