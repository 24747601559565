import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';

/** component */
import {
	DetailHeader,
	DetailScoreMenu,
	DetailExplanation,
	PostInfo,
	LinkBtn,
	Spinner,
	DetailRadarChart,
} from '@/components';
import { DetailDraftContainer } from '@/containers';
import { useRedux } from '@/hooks';

import { useDispatch } from 'react-redux';
import { toggleScoreDetailVisible } from '@/app/reducer/detailpage/detailSlice';
import { cls, getQuestionInfoFromSession } from '@/utils';
import _debounce from 'lodash.debounce';
import { HOMEPAGE } from '@/utils/url';
import { useParams } from 'react-router-dom';

/** type */
interface DetailProps {
	setDetail: Dispatch<SetStateAction<boolean>>;
}

const scoreBoardMargin = 0.75 * 16; // rem
const scoreBoardWidth = 300; // detailVisible active :300 | deactive :500
const scoreExplanation = 600;
const scoreExplanationMargin = 0.75 * 16; // rem
let totalSideWidth =
	scoreBoardWidth +
	scoreExplanation +
	scoreBoardMargin +
	scoreExplanationMargin;

// const tableHeaderHeight = 115 + 1.25 * 16 * 2 + (35 + 0.5 * 16); // height + margin(px) + 여백
const tableHeaderHeight = 300;

export default function DetailPage({ setDetail }: DetailProps) {
	const [staticWidth, setStaticWidth] = useState(scoreExplanation);
	const [staticHeight, setStaticHeight] = useState(window.innerHeight);
	const [isLoadingDetailData, setisLoadingDetailData] = useState(false);
	const { score, loading } = useRedux('detail');
	const { listVisible, detailVisible } = score;
	const {
		isLoadingCompleted,
		isPredictCompleted,
		isLqInfoCompleted,
		isAvgScoreCompleted,
	} = loading;
	const [loadingStatus, setLoadingStatus] = useState([
		{
			isLqInfoCompleted,
			message: 'LQ 지수 분석중...',
		},
		{
			isLoadingCompleted,
			message: '자질 분석중...',
		},
		{
			isPredictCompleted,
			message: '기계 채점중...',
		},
	]);
	const { id } = useParams();
	const dispatch = useDispatch();

	useEffect(() => {
		setDetail(true);

		return () => setDetail(false);
	}, []);

	useEffect(() => {
		function setViewPortXY() {
			// score
			const WIDTH = Math.floor(window.innerWidth * 0.9);

			console.log('innerWidth', window.innerWidth);
			// 만약 1200px가 넘는다면 DetailExplanation크기를 더 줄여준다.

			// if (window.innerWidth > 1700) {
			// 	scoreExplanation = 850;
			// } else if (window.innerWidth > 1550 && window.innerWidth <= 1700) {
			// 	scoreExplanation = 700;
			// } else if (window.innerWidth > 1380 && window.innerWidth <= 1550) {
			// 	scoreExplanation = 600;
			// } else if (window.innerWidth > 1200 && window.innerWidth <= 1380) {
			// 	scoreExplanation = 570;
			// }
			// if (window.innerWidth < 768) {
			// 	scoreExplanation = 300;
			// }
			totalSideWidth =
				scoreBoardWidth +
				scoreExplanation +
				scoreBoardMargin +
				scoreExplanationMargin;

			setStaticWidth(
				detailVisible
					? WIDTH - totalSideWidth // scoreBoard의 줄어든 200을 더해준다.
					: WIDTH - scoreBoardWidth - scoreBoardMargin * 2,
			);

			setStaticHeight(window.innerHeight - tableHeaderHeight);
		}

		// const temp = _debounce(setViewPortXY, 1000);
		setViewPortXY();
		window.addEventListener('resize', setViewPortXY);

		// return () => window.removeEventListener('resize', setViewPortXY);
	}, []);

	useEffect(() => {
		const WIDTH = Math.floor(window.innerWidth * 0.9);

		setStaticWidth(
			detailVisible
				? WIDTH -
						scoreBoardWidth -
						scoreBoardMargin -
						0.5 * 2 -
						scoreExplanation
				: WIDTH - scoreBoardWidth - scoreBoardMargin,
		);
	}, [detailVisible]);

	useEffect(() => {
		if (
			isLoadingCompleted &&
			isPredictCompleted &&
			isLqInfoCompleted &&
			isAvgScoreCompleted
		) {
			setisLoadingDetailData(true);
		}
	}, [
		isLoadingCompleted,
		isPredictCompleted,
		isLqInfoCompleted,
		isAvgScoreCompleted,
		isLoadingCompleted,
	]);

	return (
		<>
			<h1 className="sr-only">상세페이지</h1>
			<section className="flex flex-col responsive-screen mx-auto">
				<h2 className="sr-only">상세정보</h2>

				<div className="mt-32 text-[16px] mx-auto w-full mb-5 top-[110px] z-10 bg-white">
					<h1 className="responsive-header-text font-bold w-full text-[38px] mb-6">
						SKA Automated Scoring System
					</h1>
					<div className="shadow-md">
						<PostInfo />
					</div>
					<div className="flex justify-center items-center fixed left-0 top-[110px] h-[100px] w-[5vw]">
						<LinkBtn
							url={`/${HOMEPAGE}/${id}/posts`}
							fontSize="calc(4vw)"
							endScroll
						/>
					</div>
				</div>
				<div className="mb-5">
					<DetailHeader />
				</div>

				{!isLoadingDetailData && (
					<Spinner
						text={
							<>
								{!isLqInfoCompleted ? <p>LQ 지수 분석중... (1 / 3)</p> : null}
								{isLqInfoCompleted && !isLoadingCompleted ? (
									<p>자질 분석중... (2 / 3)</p>
								) : null}
								{isLqInfoCompleted &&
								isLoadingCompleted &&
								!isPredictCompleted ? (
									<p>기계 채점중... (3 / 3)</p>
								) : null}
							</>
						}
					/>
				)}

				<div className="flex w-full h-full">
					<div
						className="flex flex-col transition-all duration-500 ease-in-out"
						style={{ width: staticWidth, height: staticHeight }}
					>
						<DetailDraftContainer />
					</div>

					<div
						className="ml-3 transition-all duration-500 ease-in-out relative"
						style={{ width: `${detailVisible ? scoreExplanation : 0}px` }}
					>
						{detailVisible && (
							<button
								className="absolute -top-5 right-2 text-black/70 text-[15px] hover:text-black"
								type="button"
								onClick={() => dispatch(toggleScoreDetailVisible())}
							>
								<span>상세창 닫기 X</span>
							</button>
						)}
						{detailVisible && <DetailExplanation />}
					</div>

					<div
						className={cls(
							// `${detailVisible ? '' : 'mt-5'}`,
							'flex flex-col h-full ml-3 transition-all duration-300 ease-in-out',
						)}
						style={{
							width: `${scoreBoardWidth}px`,
						}}
					>
						<DetailScoreMenu />
						<div className={cls('mt-5', `w-[${scoreBoardWidth}px]`)}>
							<DetailRadarChart
								title="영역별 기계와 인간채점 점수 비교"
								width={scoreBoardWidth}
								height={detailVisible ? 400 : 450}
							/>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
