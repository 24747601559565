/* eslint-disable react/no-array-index-key */
/* eslint-disable no-prototype-builtins */
/* eslint-disable camelcase */
import React, {
	useState,
	useEffect,
	SetStateAction,
	Dispatch,
	useRef,
} from 'react';
import axios from 'axios';

/** components */
import { Portal, Spinner, CloseBtn } from '@/components';
import XLSX from 'xlsx';
import Swal from 'sweetalert2';

/** css */
import './modal.scss';
// import 'antd/lib/button/style/index.css';

import { Button } from 'antd';
import 'antd/lib/button/style/index.css';
import {
	BASE_URL,
	getCookie,
	getQuestionFromSession,
	UPLOAD,
	USER_TOKEN,
	acceptableFileName,
	tableHeader,
	excelSample,
	excelFileType,
	excelFileExtension,
	excelAllDownload,
	excelSampleDownload,
	cls,
} from '@/utils';
import { useKeyEscape, useModal } from '@/hooks';
import { setMoveToTable } from '@/app/reducer/questionpage/questionSlice';
import { useDispatch } from 'react-redux';

type ModalControlProps = {
	setShowUploadModal: Dispatch<SetStateAction<boolean>>;
	setReFetchDataBoolean: Dispatch<SetStateAction<boolean>>;
};

const excelFileName = 'excel_sample';

export default function UploadModal({
	setShowUploadModal,
	setReFetchDataBoolean,
}: ModalControlProps) {
	const hiddenInputRef = useRef<HTMLInputElement>(null);
	const [isUpload, setIsUpload] = useState(false);
	const [uploadFileName, setUploadFileName] = useState('');
	const [uploadFile, setUploadFile] = useState<any>();
	const [sheetNames, setSheetNames] = useState<string[]>(['Sheet1']);
	const [dataReady, setDataReady] = useState<boolean>(false);
	const [isClickedUpload, setIsClickedUpload] = useState<boolean>(false);
	const [dataFormatError, setDataFormatError] = useState<boolean>(false);
	const dispatch = useDispatch();

	useModal();
	/*
    {
      "Sheet1":{},
      "Sheet2":{},
    }
  */

	interface test {
		[key: string]: any;
	}

	const [sheetData, setSheetData] = useState<test>({});

	useEffect(() => {
		const showExcel = (e: any) => {
			if (e.key === 'Escape') setShowUploadModal(false);
		};
		window.addEventListener('keyup', showExcel);

		return () => {
			window.removeEventListener('keyup', showExcel);
		};
	}, []);

	function handlecancelUpload() {
		if (hiddenInputRef.current) {
			hiddenInputRef.current.value = '';
		}
		setIsUpload(false);
		setSheetData({ sheet1: {} });
		setDataReady(false);
	}

	function checkFileName(name: string) {
		const data = name.split('.').pop()?.toLowerCase();

		if (data === undefined || data === null) return false;
		return acceptableFileName.includes(data);
	}

	function readDataFromExcel(data: any): boolean {
		const wb = XLSX.read(data);

		setSheetNames(wb.SheetNames);

		const mySheetData: { [key: string]: any } = {};

		// loop through the sheets
		for (let i = 0; i < wb.SheetNames.length; i += 1) {
			const sheetName = wb.SheetNames[i];
			const worksheet = wb.Sheets[sheetName];
			const jsonData = XLSX.utils.sheet_to_json(worksheet, {
				blankrows: true,
				header: 1,
			});
			mySheetData[sheetName] = jsonData;
		}

		if (
			JSON.stringify(mySheetData[wb.SheetNames[0]][0]) !==
			JSON.stringify(tableHeader)
		) {
			let timerInterval: any;
			Swal.fire({
				title: 'Error',
				html: '엑셀에 입력된 데이터 형식이 잘못되었습니다.',
				timer: 2500,
				timerProgressBar: true,

				willClose: () => {
					clearInterval(timerInterval);
				},
			});

			return false;
		}
		setSheetData(mySheetData);
		return true;
	}

	async function onUploadExcel(e: any) {
		const myFile = e.target.files[0];

		if (!checkFileName(myFile.name)) {
			setUploadFileName('');
			alert('잘못된 파일 형식입니다.');

			handlecancelUpload();
			return;
		}

		/** read the XLSX MetaData */
		const data = await myFile.arrayBuffer();

		if (readDataFromExcel(data)) {
			setIsUpload(true);
			setUploadFile(myFile);
			setUploadFileName(myFile.name);
		} else {
			handlecancelUpload();
		}
	}

	async function handleOk() {
		setIsClickedUpload(true);

		const cookieHash = getCookie(USER_TOKEN);

		const formData = new FormData();
		const file = uploadFile;
		formData.append('file', file);

		try {
			const res = await axios.post(
				`${BASE_URL}/${UPLOAD}/${getQuestionFromSession()}`,
				formData,
				{
					headers: {
						Authorization: cookieHash,
						'Content-Type': 'multipart/form-data',
					},
				},
			);

			if (res.status === 200 || res.status === 201) {
				Swal.fire({
					icon: 'success',
					title: 'Success',
					text: '업로드 되었습니다.',
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: '확인',
					heightAuto: false,
				}).then((result) => {
					if (result.isConfirmed) {
						setReFetchDataBoolean(true);
						setShowUploadModal(false);
						dispatch(setMoveToTable(true));
					}
				});
			}
		} catch (e) {
			console.error(e);
			setIsClickedUpload(false);
			Swal.fire({
				title: 'Error',
				text: `업로드에 실패했습니다.`,
				icon: 'error',
				heightAuto: false,
			});
		}
	}

	function handleCancelModal() {
		setShowUploadModal(false);
	}

	return (
		<Portal>
			<div className="relative">
				<h1 className="sr-only">문항에 대한 글 파일 업로드</h1>
				<div className="background">
					<div className="modal__upload rounded-lg w-full h-full flex justify-center items-center">
						<div className="absolute top-0 right-0 flex justify-end">
							<CloseBtn setShowModal={setShowUploadModal} />
						</div>
						<form
							method="post"
							encType="multipary/form-data"
							target="_blank"
							className={`w-full h-[85vh] flex flex-col justify-center px-10${
								isClickedUpload ? ' opacity-20' : ''
							}`}
						>
							<div className="flex mb-5">
								<button
									type="button"
									className="w-[300px] h-[40px] flex justify-center items-center text-white bg-green-600 rounded-sm text-sm"
									onClick={() => excelSampleDownload()}
								>
									엑셀 양식 다운
								</button>

								<label
									className="hover:cursor-pointer flex w-[300px] h-[30px]"
									htmlFor="file-upload"
								>
									<div className="w-full h-[40px] flex">
										<span className="w-full h-full flex justify-center items-center text-white bg-btn-blue rounded-sm text-sm">
											파일 업로드
										</span>
									</div>
								</label>

								<span
									className={`w-full h-[40px] border pl-3 ml-3 flex items-center relative ${
										isUpload ? 'text-black' : 'text-gray-400'
									}`}
								>
									{isUpload ? uploadFileName : 'No file selected.'}
									{isUpload ? (
										<button
											className="absolute right-0 p-3 rounded-lg text-[20px] text-red-600/20 font-bold hover:text-red-600/100"
											type="button"
											onClick={() => handlecancelUpload()}
										>
											X
										</button>
									) : null}
								</span>
								<input
									className="opacity-0 sr-only"
									accept=".xls, .xlsx"
									multiple={false}
									id="file-upload"
									name="file-upload"
									type="file"
									ref={hiddenInputRef}
									onChange={(e) => onUploadExcel(e)}
								/>
							</div>
							<div className="w-full h-[700px] mb-5 border overflow-scroll overflow-x-auto overflow-y-auto">
								<table className="border w-full">
									<thead className="border">
										<tr className="">
											{sheetData.hasOwnProperty(sheetNames[0])
												? sheetData[sheetNames[0]][0].map(
														(elem: string, idx: number) => (
															<th
																className={cls(
																	'px-4 border',
																	`${elem === '아이디' ? 'min-w-[80px]' : ''}`,
																	`${
																		elem !== '아이디' && elem !== '작문'
																			? 'min-w-[130px]'
																			: ''
																	}`,
																)}
																key={`th_${elem}_${idx}`}
															>
																<span>{elem}</span>
															</th>
														),
												  )
												: tableHeader.map((elem, idx: number) => (
														<th
															className={cls('px-4 border')}
															key={`th_${elem}_${idx}`}
														>
															<span>{elem}</span>
														</th>
												  ))}
										</tr>
									</thead>
									<tbody className="">
										{sheetData.hasOwnProperty(sheetNames[0]) ? (
											sheetData[sheetNames[0]].map((elem: any, idx: number) => {
												if (idx === 0) return null;
												return (
													<tr key={`tr_${elem}_${idx}`}>
														{elem.map((item: string, idx2: number) => (
															<td
																key={`td_${elem}_${idx2}`}
																className="border "
															>
																<span
																	className={`${
																		idx2 === 1
																			? 'w-[350px] modal__upload__span flex items-center'
																			: 'flex justify-center items-center'
																	}`}
																>
																	{item}
																</span>
															</td>
														))}
													</tr>
												);
											})
										) : (
											<tr>
												<td className="w-full" colSpan={tableHeader.length}>
													<div className="flex justify-center items-center">
														<span className="text-[17px] text-black/50">
															데이터를 입력해주세요.
														</span>
													</div>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							<div className="flex justify-center items-center">
								<Button
									className="w-20 mr-3"
									type="primary"
									size="large"
									ghost
									onClick={() => handleOk()}
								>
									업로드
								</Button>

								<Button
									className="w-20"
									type="ghost"
									size="large"
									danger
									onClick={() => handleCancelModal()}
								>
									취소
								</Button>
							</div>
						</form>
						{isClickedUpload && <Spinner text="엑셀 파일 업로드..." />}
					</div>
				</div>
			</div>
		</Portal>
	);
}
