import { useModal } from '@/hooks';
import React from 'react';
import { Oval } from 'react-loader-spinner';
import { Portal } from '../atomic';

interface SpinnerProps {
	text?: string | JSX.Element;
}

export default function Spinner({ text }: SpinnerProps) {
	useModal();

	return (
		<Portal>
			<div className="fixed z-20 top-0 left-0 w-full h-full bg-black/80">
				<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20">
					<div className="flex flex-col justify-center items-center">
						<Oval
							height={80}
							width={80}
							color="#2297F4"
							visible
							ariaLabel="oval-loading"
							secondaryColor="#8FCAF9"
							strokeWidth={5}
							strokeWidthSecondary={4}
						/>
						<span className="text-white">{text}</span>
					</div>
				</div>
			</div>
		</Portal>
	);
}

Spinner.defaultProps = {
	text: '',
};
