import React, { useEffect, useState, memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
/** components */
// import LogoutModal from '../Modal/LogoutModal';

/** utils */
import {
	USER_TOKEN,
	deleteCookie,
	saveScrollBoolToSession,
	QUESTION_LIST,
} from '@/utils';

import '@/style/index.scss';
import { MAIN_SCROLL_HANDLE } from '@/utils/scroll';
import { useDispatch } from 'react-redux';
import { setIsLogin } from '@/app/reducer/login/loginSlice';
import {
	setClickLogo,
	setMoveToTable,
	setShowDataModal,
} from '@/app/reducer/questionpage/questionSlice';
import { useRedux } from '@/hooks';
import { DataModal } from '../modal';

export default memo(() => {
	const { isLogin } = useRedux('login');

	const navigate = useNavigate();
	const dispatch = useDispatch();

	function handleLogout() {
		deleteCookie(USER_TOKEN);
		dispatch(setIsLogin(false));
		navigate('/');
		// setIsLogin(false);
	}

	return (
		<header className="h-header-height bg-white w-full fixed z-20 top-0 outline-2 justify-center flex border">
			<div role="none" className="responsive-screen flex-center">
				<Link
					className="hover:opacity-50"
					to={`/${QUESTION_LIST}`}
					onClick={() => {
						saveScrollBoolToSession(MAIN_SCROLL_HANDLE, false);
						dispatch(setMoveToTable(false));
						dispatch(setClickLogo(true));
					}}
				>
					<img
						className="w-[650px] h-[140px]"
						src="/sejong-logo.png"
						alt="logo"
					/>
				</Link>

				<nav className="w-full h-full flex justify-end items-center">
					<ol className="h-full flex-center">
						{isLogin && (
							<>
								<li>
									<button
										type="button"
										className="hover:underline hover:decoration-black "
										onClick={() => dispatch(setShowDataModal(true))}
									>
										데이터 통계
									</button>
								</li>
								<li>
									<input
										className="bg-main-blue text-white px-3 py-1 ml-5 hover:cursor-pointer hover:opacity-80"
										title="아니요"
										value="로그아웃"
										type="button"
										onClick={handleLogout}
									/>
								</li>
							</>
						)}
					</ol>
				</nav>
			</div>
		</header>
	);
});
