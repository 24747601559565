import { useModal, useRedux } from '@/hooks';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setShowDataModal } from '@/app/reducer/questionpage/questionSlice';

import { Button } from 'antd';
import { Portal } from '../atomic';
import { CloseBtn } from '../btns';
import 'antd/lib/button/style/index.css';

export default function DataModal() {
	const dispatch = useDispatch();
	useModal();

	useEffect(() => {
		const setShow = (e: any) => {
			if (e.key === 'Escape') dispatch(setShowDataModal(false));
		};

		window.addEventListener('keyup', setShow);

		return () => window.removeEventListener('keyup', setShow);
	}, []);

	return (
		<Portal>
			<div className="relative">
				<h1 className="sr-only">데이터 통계</h1>
				<div className="data-background">
					<div className="modal__data bg-white rounded-lg w-full h-full overflow-scroll overflow-y-auto overflow-x-hidden">
						<div className="sticky top-0 right-5 flex justify-end">
							<Button
								size="large"
								type="primary"
								danger
								onClick={() => {
									dispatch(setShowDataModal(false));
								}}
							>
								X
							</Button>
						</div>
						<div className="w-full p-3 px-20">
							<h2 className="font-bold text-[32px] flex justify-center mb-5">
								SKA 2022 AI 자동평가 데이터 통계
							</h2>
							<div className="flex">
								<table className="first">
									<thead>
										<tr>
											<th scope="col">항목</th>
											<th scope="col">인원수</th>
										</tr>
									</thead>
									<tfoot>
										<tr>
											<th scope="row">합계</th>
											<td>2,206명</td>
										</tr>
									</tfoot>
									<tbody>
										<tr>
											<td>전체 응시 인원(A형)</td>
											<td>
												<span>1,244명</span>
											</td>
										</tr>
										<tr>
											<td>
												<span>전체 응시 인원(B형)</span>
											</td>
											<td>962명</td>
										</tr>
									</tbody>
								</table>

								<table className="first ml-5">
									<thead>
										<tr>
											<th scope="col">항목</th>
											<th scope="col">인원수</th>
										</tr>
									</thead>
									<tfoot>
										<tr>
											<th scope="row">합계</th>
											<td className="text-red-500 font-bold">1,714명</td>
										</tr>
									</tfoot>
									<tbody>
										<tr>
											<td>유효 응시 인원(A형)</td>
											<td>949명</td>
										</tr>
										<tr>
											<td>
												<span>유효 응시 인원(B형)</span>
											</td>
											<td>765명</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="flex justify-center p-10">
								<span className="mr-3">
									총 답안 개수: <span className="font-bold">{1714 * 5} 개</span>
								</span>
								<span> ( 1714 * 5 = 8570 )</span>
							</div>

							<table className="second w-full">
								<thead>
									<tr>
										<th scope="col">항목</th>
										<th scope="col">개수</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span>1, 2번 채점자 모두 0점 부여한 데이터 제외</span>
										</td>
										<td>
											<span>5,211개</span>
											<span className="text-[12px] ml-1">
												(답안 작성 X, 분석 의미 없는 데이터 제외)
											</span>
										</td>
									</tr>
									<tr>
										<td>
											<span>
												1, 2번 채점자 중 1명이라도 0점 부여한 데이터 제외
											</span>
										</td>
										<td>
											<span />
											4,514개
											<span className="text-[12px] ml-1">
												(Copy, 컨닝 제외)
											</span>
										</td>
									</tr>

									<tr>
										<td>
											<span>학습용 데이터(76%):</span>
										</td>
										<td>
											<span>3,425개</span>
										</td>
									</tr>

									<tr>
										<td>
											<span>검증용 데이터(24%):</span>
										</td>
										<td>
											<span className="text-red-500 font-bold">1,089개</span>
										</td>
									</tr>
								</tbody>
							</table>

							<div className="flex justify-center p-10">
								<span className="mr-3">
									총 검증 데이터 :{' '}
									<span className="font-bold">{1089 * 3} 개</span>
								</span>
								<span> ( 1089 * 3 = 3267 )</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Portal>
	);
}
