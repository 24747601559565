// httpOnly 속성이 붙어야 되는 것이 맞지만, 현재 클라이언트에서 쿠키를 생성해서 보내주고 있으므로 임시로 빼놓는다.

export const USER_TOKEN = 'ska-user-token';

/**
 * @param {string} cookieName 쿠키의 key 값
 * @returns {string} 쿠키의 value
 */
export function getCookie(cookieName: string): string {
	let cookieVal = '';

	const arr = document.cookie.replaceAll(' ', '').split(';');
	arr.forEach((elem) => {
		const [key, val] = elem.split('=');
		if (key === cookieName) {
			cookieVal = val;
		}
	});
	return cookieVal;
}

type Cookie = {
	[key: string]: string | object | boolean | Date;
};

/**
 * https://ko.javascript.info/cookie
 * 쿠키에 대한 자세한 정보
 * @param {string} cookieKey 쿠키의 key
 * @param {string} cookieVal 쿠키의 value
 * @param {object} options.path URL path(경로)의 접두사로, 이 경로나 이 경로의 하위 경로에 있는 페이지만 쿠키에 접근할 수 있습니다. 절대 경로이어야 하고, (미 지정시) 기본값은 현재 경로입니다.
 * @param {Date} options.expires expires(유효 일자)나 max-age(만료 기간) 옵션이 지정되어있지 않으면, 브라우저가 닫힐 때 쿠키도 함께 삭제됩니다. 이런 쿠키를 "세션 쿠키(session cookie)"라고 부릅니다.
 * @param {boolean} options.secure 이 옵션을 설정하면 HTTPS로 통신하는 경우에만 쿠키가 전송됩니다.
 */
export function setCookie(
	cookieKey: string,
	cookieVal: string,
	options: object = {},
): void {
	const cookieOptions: Cookie = {
		path: '/',
		expires: new Date(Date.now() + 86400e3),
		secure: false,
		...options,
	};
	if (cookieKey === '') {
		console.log("enter cookie's key!");
		return;
	}

	let updatedCookie = `${encodeURIComponent(cookieKey)}=${encodeURIComponent(
		cookieVal,
	)}`;

	for (const optionKey in cookieOptions) {
		if (Object.prototype.hasOwnProperty.call(cookieOptions, optionKey)) {
			updatedCookie += `; ${optionKey}`;
			const optionValue = cookieOptions[optionKey];
			if (optionValue !== true) {
				updatedCookie += `=${optionValue}`;
			}
		}
	}
	document.cookie = updatedCookie;
}

/**
 * @param cookieName 삭제할 쿠키의 key
 */
export function deleteCookie(...args: string[]): void {
	args.forEach((elem) => {
		setCookie(elem, '', {
			'max-age': -1,
		});
	});
}
