import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export const checkSlice = createSlice({
	name: 'check',
	initialState: {
		checkAll: false,
		deleteAll: false,
		deleteSelected: false,
	},
	reducers: {
		toggleCheckAll: (state) => {
			state.checkAll = !state.checkAll;
		},
		setCheckAll: (state, action: PayloadAction<boolean>) => {
			state.checkAll = action.payload;
		},
		toggleDeleteAll: (state) => {
			state.deleteAll = !state.deleteAll;
		},
		setDeleteAll: (state, action: PayloadAction<boolean>) => {
			state.deleteAll = action.payload;
		},
		toggleDeleteSelected: (state) => {
			state.deleteSelected = !state.deleteSelected;
		},
		setDeleteSelected: (state, action: PayloadAction<boolean>) => {
			state.deleteSelected = action.payload;
		},
	},
});

export const {
	toggleCheckAll,
	setCheckAll,
	toggleDeleteAll,
	setDeleteAll,
	toggleDeleteSelected,
	setDeleteSelected,
} = checkSlice.actions;
export default checkSlice.reducer;
