import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export const questionSlice = createSlice({
	name: 'question',
	initialState: {
		isExcelDownloaded: false,
		moveToTable: false,
		clickLogo: false,
		showDataModal: false,
	},
	reducers: {
		setExcelDownloaded: (state, action: PayloadAction<boolean>) => {
			state.isExcelDownloaded = action.payload;
		},
		setMoveToTable: (state, action: PayloadAction<boolean>) => {
			state.moveToTable = action.payload;
		},
		setClickLogo: (state, action: PayloadAction<boolean>) => {
			state.clickLogo = action.payload;
		},
		setShowDataModal: (state, action: PayloadAction<boolean>) => {
			console.log(action.payload);
			state.showDataModal = action.payload;
		},
	},
});

export const {
	setExcelDownloaded,
	setMoveToTable,
	setClickLogo,
	setShowDataModal,
} = questionSlice.actions;
export default questionSlice.reducer;
