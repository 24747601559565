/** api */
import { getQuestions } from '@/api/question';

/** utils */
import {
	cls,
	getQuestionInfoFromSession,
	saveQuestionInfoToSession,
} from '@/utils';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Oval } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';

const tableHeader = [
	'번호',
	'문제형태',
	'문제',
	'보기',
	'답안길이',
	'모범답안',
];

function PostInfo() {
	const [questionInfo, setQuestionInfo] = useState(() =>
		getQuestionInfoFromSession(),
	);
	const [postInfoDataLoaded, setPostInfoDataLoaded] = useState(false);

	const { id } = useParams();

	useEffect(() => {
		async function refetch() {
			if (JSON.stringify(getQuestionInfoFromSession()) === JSON.stringify({})) {
				const res = await getQuestions();
				setQuestionInfo(
					res.results.filter((key: any) => id && key.id === +id)[0],
				);
				saveQuestionInfoToSession(
					res.results.filter((key: any) => id && key.id === +id)[0],
				);

				setPostInfoDataLoaded(true);
			}
		}

		// 데이터가 없을 경우
		const data = getQuestionInfoFromSession();
		if (id && data && data.id === +id) setPostInfoDataLoaded(true);
		if (id && data && data.id !== +id) refetch();
		if (JSON.stringify({}) === JSON.stringify(data)) refetch();
	}, []);

	return (
		<>
			<table className="w-full bg-white border-none">
				<thead className="">
					<tr className="text-[16px]">
						{tableHeader.map((elem) => (
							<th
								key={elem}
								className={cls(
									'border border-black/30 bg-main-blue/30 px-2',
									`${elem === '번호' ? 'min-w-[80px]' : ''}`,
									`${elem === '답안길이' ? 'min-w-[80px]' : ''}`,
								)}
							>
								<span className="">{elem}</span>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					<tr className="text-[12px] 2xl:text-[15px] leading-5 align-middle text-start min-h-[60px]">
						{!postInfoDataLoaded && (
							<td
								colSpan={6}
								className="flex w-full justify-center items-center table-cell"
							>
								<Oval
									wrapperClass="flex justify-center items-center"
									color="#2297F4"
									visible
									ariaLabel="oval-loading"
									secondaryColor="#8FCAF9"
									strokeWidth={5}
									strokeWidthSecondary={4}
								/>
							</td>
						)}
						{postInfoDataLoaded && (
							<>
								<td className="border p-3 text-center">
									<span className="block min-w-[50px] max-w-[80px]">{`${questionInfo.no}`}</span>
									<span className="block min-w-[50px] max-w-[80px]">{`(${questionInfo.id})`}</span>
								</td>
								<td className="border p-3">
									<span className="block max-h-[50px] min-w-[100px] max-w-[150px] overflow-scroll overflow-x-hidden overflow-y-auto leading-5">
										{questionInfo.type.type}
									</span>
								</td>
								<td className="border p-3">
									<span className="block max-h-[50px] min-w-[190px] max-w-[200px] overflow-scroll overflow-x-hidden overflow-y-auto leading-5">
										{questionInfo.text}
									</span>
								</td>
								<td className="border p-3">
									<span className="block max-h-[60px] w-[350px] overflow-scroll  overflow-y-auto overflow-x-hidden leading-5">
										{questionInfo.sample}
									</span>
								</td>
								<td className="border p-3 text-center">
									<span>{`${questionInfo.min_len}~${questionInfo.max_len}`}</span>
								</td>
								<td className="border p-3">
									<span className="block max-h-[60px] min-w-[200px] max-w-full overflow-scroll overflow-x-hidden overflow-y-auto leading-5 whitespace-pre-wrap">
										{questionInfo.answer}
									</span>
								</td>
							</>
						)}
					</tr>
				</tbody>
			</table>
		</>
	);
}

export default React.memo(PostInfo);
