import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

/** components */
import Swal from 'sweetalert2';
import { DetailDraft, UpdateWithCancel } from '@/components';
import { Editor, EditorState, ContentState } from 'draft-js';

/** utils */
import {
	getCookie,
	USER_TOKEN,
	BASE_URL,
	POSTS,
	TEXT_LIMIT,
	QUESTION_LIST,
} from '@/utils';
import { DataType } from '@/utils/question';

/** type */
import type { AxiosResponse } from 'axios';
import { saveCurrentPage } from '@/app/reducer/postpage/postSlice';
import { useAppDispatch } from '@/app/hooks';
import { isCallExpression } from 'typescript';

export default function NewPost() {
	const [titleValue, setTitleValue] = useState<string>('');
	const [isFocused, setIsFocused] = useState<boolean>(false);
	const [editorState, setEditorState] = useState(() =>
		EditorState.createEmpty(),
	);
	const [newText, setNewText] = useState<string>('');
	const [textLengthValid, setTextLengthValid] = useState<boolean>(false);
	const [titleValid, setTitleValid] = useState<boolean>(false);
	const [editorValid, setEditorValid] = useState<boolean>(false);
	const [testerId, setTesterId] = useState<string>('');
	const [isClickedSaveBtn, setIsClickedSaveBtn] = useState<boolean>(false);

	const editorLocateRef = useRef<HTMLDivElement>(null);
	const editorTitleFocusRef = useRef<HTMLInputElement>(null);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { id } = useParams();

	/** Title input 박스로 포커싱 */
	useEffect(() => {
		editorTitleFocusRef.current?.focus();
	}, []);

	/** 스로틀 혹은 디바운스 */
	// editorState에 Focus와 Blur가 다 들어가있기 때문에 useEffect에 자꾸 걸린다.
	useEffect(() => {
		const prevText = editorState.getCurrentContent().getPlainText();

		if (prevText.length >= TEXT_LIMIT) {
			const temp = prevText.slice(0, TEXT_LIMIT - 1);
			const $editorState = EditorState.moveFocusToEnd(
				EditorState.createWithContent(ContentState.createFromText(temp)),
			);
			setEditorState($editorState);
			setTextLengthValid(true);
		}

		setNewText(editorState.getCurrentContent().getPlainText());
	}, [editorState]);

	useEffect(() => {
		if (textLengthValid) alert('3000자 이상을 넘을 수 없습니다.');

		return () => setTextLengthValid(false);
	}, [textLengthValid]);

	async function handleOk() {
		const cookieHash = getCookie(USER_TOKEN);
		const { CancelToken } = axios;
		const source = CancelToken.source();

		setIsClickedSaveBtn(true);

		if (titleValue.length === 0) return;
		if (newText.length === 0) return;

		setIsClickedSaveBtn(false);

		try {
			const res: AxiosResponse = await axios({
				url: `/${BASE_URL}/${POSTS}/`,
				method: 'post',
				data: {
					title: titleValue,
					content: newText,
					tester_id: testerId,
					question_id: Number(id),
				},
				headers: {
					Authorization: cookieHash,
				},
				cancelToken: source.token,
			});

			if (res.data.status === 'success') {
				navigate(`/${QUESTION_LIST}/${id}/${POSTS}`);
				dispatch(saveCurrentPage(1));
			}
		} catch (error) {
			if (axios.isCancel(error)) {
				console.error('Request', error);
				navigate(-1);
			}
		}
	}

	function handleCancel() {
		Swal.fire({
			icon: 'warning',
			html: `현재 페이지를 닫으시겠습니까?<br/>저장되지 않은 정보는 모두 삭제됩니다.`,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: '예',
			cancelButtonText: '아니오',
			heightAuto: false,
		}).then((result) => {
			if (result.isConfirmed) {
				navigate(`/${QUESTION_LIST}/${id}/${POSTS}`);
			}
		});
	}

	useEffect(() => {
		if (titleValue.length > 0) setTitleValid(false);
		else setTitleValid(true);
	}, [titleValue]);

	useEffect(() => {
		if (newText.length > 0) setTitleValid(false);
	}, [newText]);

	function handleFocusing() {
		setIsFocused((prev) => !prev);
	}

	return (
		<>
			<label
				htmlFor="editor_title mt-30"
				className={`${titleValue.length >= 30 ? '' : ' mb-5'}`}
			>
				<div className="flex items-center w-full">
					<div className="w-[90%] mr-2">
						<input
							type="span"
							value={titleValue}
							onChange={(e) => setTitleValue(e.target.value)}
							className="p-3 w-full font-bold text-[20px] outline-dashed outline-editor transition-all focus:outline-[3px] focus:outline-main-blue"
							// style={{ width: editorLocation.width }}
							placeholder={titleValue !== '' ? '' : '제목을 입력해주세요.'}
							maxLength={30}
							ref={editorTitleFocusRef}
							required
						/>
						{isClickedSaveBtn && titleValue.length === 0 ? (
							<div className="text-red-500">제목을 입력해주세요.</div>
						) : (
							<div className="min-h-[25px]" />
						)}
					</div>
					<div className="w-[100%]">
						<input
							type="span"
							value={testerId}
							onChange={(e) => setTesterId(e.target.value)}
							className="p-3 w-full font-bold text-[20px] outline-dashed outline-editor transition-all focus:outline-[3px] focus:outline-main-blue"
							// style={{ width: editorLocation.width }}
							placeholder={testerId !== '' ? '' : '응시자 ID를 입력해주세요.'}
							maxLength={30}
							required
						/>
						{isClickedSaveBtn && testerId.length === 0 ? (
							<div className="text-red-500">응시자 ID를 입력해주세요.</div>
						) : (
							<div className="min-h-[25px]" />
						)}
					</div>
				</div>
			</label>
			{titleValue.length >= 30 ? (
				<span className="text-red-600 font-bold p-1 text-[14px]">
					제목은 30자를 넘을 수 없습니다.
				</span>
			) : null}

			<div
				role="textbox"
				tabIndex={0}
				className={`p-5 pr-0 flex flex-col h-[60vh] outline-none outline-1 outline-editor transition-all text-[18px] 2xl:text-[20px] leading-6 hover:cursor-text overflow-auto${
					isFocused ? ' outline-main-blue/80 outline-2' : ''
				}`}
				onKeyUp={() => null}
				ref={editorLocateRef}
			>
				<Editor
					editorState={editorState}
					onChange={setEditorState}
					stripPastedStyles
					placeholder="글을 작성해주세요."
					onFocus={() => handleFocusing()}
					onBlur={() => handleFocusing()}
				/>
			</div>
			{isClickedSaveBtn && newText.length === 0 ? (
				<div className="mb-1 text-red-500">내용을 입력해주세요.</div>
			) : null}
			<div className="mt-3 flex items-center justify-between text-sm">
				<span className="self-start">{newText.length} / 3000</span>
				<div>
					<UpdateWithCancel
						okText="등록"
						okClass="w-20 border p-2 border-main-blue text-main-blue hover:border-main-blue/100 hover:bg-main-blue/100 hover:text-white/100 transition-all rounded-lg mr-3"
						cancelText="취소"
						cancelClass="w-20 border p-2 border-red-500 text-red-500  hover:bg-red-500/100 hover:border-red-500/100 hover:text-white rounded-lg transition-all "
						handleOk={() => handleOk()}
						handleCancel={() => handleCancel()}
					/>
				</div>
			</div>
		</>
	);
}
