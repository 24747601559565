/* eslint-disable camelcase */
import React, {
	useLayoutEffect,
	useEffect,
	useState,
	useCallback,
	useRef,
} from 'react';
import axios, { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';

/** component */
import {
	Footer,
	QuestionModal,
	ExcelModal,
	ConfusionMatrix,
	ConfusionColumnChart,
	IconTextBtn,
	Spinner,
	DataModal,
} from '@/components';
import { CheckBox, TableButton } from '@/components/atomic';

import { Oval } from 'react-loader-spinner';
import Swal from 'sweetalert2';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

// css
import '@toast-ui/chart/dist/toastui-chart.min.css'; // Chart 스타일
import 'react-circular-progressbar/dist/styles.css';

/** util */
import {
	getCookie,
	USER_TOKEN,
	BASE_URL,
	QUESTIONS,
	dateString,
	POSTS,
	QUESTION_LIST,
	saveQuestionToSession,
	excelAllDownload,
	cls,
} from '@/utils';
import { DataType, WriteMode } from '@/utils/question';

/** type */
import {
	QuestionModalData,
	CustomError,
	Statistics,
	StatisticsKindData,
	ScatterScoreData,
} from '@/types';
import {
	getCheckStatusFromSession,
	Q_CHECK_STATUS,
	saveCheckStatusToSession,
	saveQuestionInfoToSession,
	saveScrollBoolToSession,
} from '@/utils/storage';

// import Excel from '../../public/excel.png';

// redux
import { useDispatch } from 'react-redux';
import { useRedux } from '@/hooks';
import { setIsMainLoaded } from '@/app/reducer/mainpage/mainSlice';
import {
	setSelectedStatisticsType,
	setStaticsticsToRedux,
} from '@/app/reducer/chartSlice';
import { ChartModal } from '@/components/modal';

import { mainPageScrollHandle, MAIN_SCROLL_HANDLE } from '@/utils/scroll';
import SwarmPlot from '@/components/chart/SwarmPlot';
import Scoll from '@/components/scroll/SmoothScroll';
import { setMoveToTable } from '@/app/reducer/questionpage/questionSlice';
import { setPageInit } from '@/app/reducer/postpage/postSlice';

export default function QuestionPage() {
	const [showModal, setShowModal] = useState<boolean>(false);
	const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
	const [showChartModal, setShowChartModal] = useState<boolean>(false);

	const [fetchQuestionData, setFetchQuestionData] = useState(
		[] as QuestionModalData[],
	);
	const [, setSelectQuestionData] = useState<QuestionModalData>(
		{} as QuestionModalData,
	);
	const [editQuestionId, setEditQuestionId] = useState<number>(0);
	const [checkAll, setCheckAll] = useState<boolean>(false);
	const [checkStatus, setCheckStatus] = useState<boolean[]>([] as boolean[]);

	// chart Width 조절을 위한 State
	const [dynamicInnerWidth, setDynamicInnerWidth] = useState<number>(
		window.innerWidth,
	);

	// 통계데이터
	const [statistics, setStatistics] = useState<Statistics>({} as Statistics);
	const [scatterData, setScatterData] = useState<Array<ScatterScoreData>>(
		[] as ScatterScoreData[],
	);

	// 수정, 새로운 글 추가 Mode
	const [mode, setMode] = useState<string>('');

	// 문항 정보가 변경되었을 때, 테이블의 데이터 리렌더링
	const [reFetchDataBoolean, setReFetchDataBoolean] = useState<boolean>(false);

	// 정확도 표시를 위한 State
	const [accArr, setAccArr] = useState<Array<number>>([]);
	// 전체 데이터를 저장하는 State
	const [columnChartData, setColumnChartData] = useState<
		Array<string | object>
	>([]);
	const [confusingMatrixData, setConfusingMatrixData] =
		useState<StatisticsKindData>();

	// 엑셀 다운로드 버튼이 눌렸는지 체크
	const [excelDownloadStart, setExcelDownloadStart] = useState<boolean>(false);
	const [excelDownloadIdx, setExcelDownloadIdx] = useState<number>(0);
	const [checkAllButton, setCheckAllButton] = useState<boolean>(false);
	const [graphMaxAxisValue, setGraphMaxAxisValue] = useState<number>(0);
	const scrollHeight = useRef<HTMLDivElement>(null);

	// mainpage에서 데이터 fetching이 완료되었을 때,
	const { loading } = useRedux('main');
	const { moveToTable } = useRedux('question');
	const { isMainLoaded } = loading;

	// const { isExcelDownloaded } = useRedux('question');
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// 문항 추가 버튼을 먼저 누르면 오류가 발생.
	// 테이블 데이터 변경시 리렌더링 사이드 이펙트이므로 페이지가 처음 렌더링 될 때의 사이드 이펙트로 의존성 분리를 해줘야 한다. 테이블 데이터가 바뀌었을 때, 차트가 바뀔 필요가 없다.
	// 변경사항이 발생할 때, 데이터를 계속 바꿔준다.
	useLayoutEffect(() => {
		const cookieHash = getCookie(USER_TOKEN);
		/** axios 요청 취소 */
		const { CancelToken } = axios;
		const source = CancelToken.source();

		(async () => {
			try {
				const res = await axios.get(`/${BASE_URL}/${QUESTIONS}/`, {
					headers: { Authorization: cookieHash },
					cancelToken: source.token,
				});

				if (res.status === 201) {
					const { results } = res.data.data;

					const {
						acc_all,
						acc_by_nos,
						datas_all,
						datas_by_no,
						scores_by_no,
						scores,
						mat,
					} = res.data.data;

					// Scatter에 들어갈 데이터
					const temp = await results.map((x: any) => ({
						qno: x.no,
						scores: x.posts.map((y: any) => {
							const auto = y.auto_score ? JSON.parse(y.auto_score) : {};
							const person = y.person_score ? JSON.parse(y.person_score) : {};
							const pscoreAuto = Object.values<number>(auto).reduce(
								(a, b) => a + b,
								0,
							);
							const pscorePerson = Object.values<number>(person).reduce(
								(a, b) => a + b,
								0,
							);
							return {
								auto: Math.round(
									(pscoreAuto / (Object.values(auto).length * 4)) * 100,
								),
								person: Math.round(
									(pscorePerson / (Object.values(person).length * 4)) * 100,
								),
							};
						}),
					}));

					// Scatter Data
					setScatterData(temp);
					const fetchData = {
						acc_all: JSON.parse(acc_all),
						acc_by_nos: JSON.parse(acc_by_nos),
						datas_all: JSON.parse(datas_all),
						datas_by_no: JSON.parse(datas_by_no),
						scores_by_no: JSON.parse(scores_by_no),
						scores: JSON.parse(scores),
						mat: JSON.parse(mat),
					};

					// Statistics Data
					setStatistics(fetchData);
					setFetchQuestionData(results);

					dispatch(setStaticsticsToRedux(fetchData));
					dispatch(setIsMainLoaded(true));
				}
			} catch (e: any) {
				// 에러 핸들링, Authorization 실패시 로그인페이지로 이동
				if (axios.isCancel(e)) {
					console.error('Request', e.message);
				}
			}
		})();

		return () => {
			source.cancel();
			dispatch(setIsMainLoaded(false));
		};
	}, [reFetchDataBoolean]);

	// 선택한 id의 QuestionId 저장
	useLayoutEffect(() => {
		const data = fetchQuestionData.filter(
			(elem) => +elem.id === editQuestionId,
		);

		setSelectQuestionData(data[0]);
	}, [editQuestionId]);

	// 전체 데이터 fetch, 세션에 저장
	useEffect(() => {
		let tempCheckStates: boolean[] = getCheckStatusFromSession(Q_CHECK_STATUS);
		tempCheckStates =
			tempCheckStates.length <= 0
				? fetchQuestionData.map(() => false)
				: tempCheckStates;

		saveCheckStatusToSession(
			Q_CHECK_STATUS,
			fetchQuestionData.map(() => false),
		);

		setCheckStatus(() => tempCheckStates);
		setCheckAll(false);

		// session에 값이 없는 경우 입력
	}, [fetchQuestionData, reFetchDataBoolean]);

	// 전체 체크 처리
	useEffect(() => {
		setCheckStatus((prev) => prev.map(() => !!checkAll));
		setCheckAllButton((prev) => !prev);
	}, [checkAll]);

	// 전체 선택 처리
	useEffect(() => {
		if (
			checkStatus.length === checkStatus.filter((elem) => elem === true).length
		) {
			setCheckAllButton(true);
		} else setCheckAllButton(false);
	}, [checkStatus]);

	// 통계데이터 저장
	useEffect(() => {
		if (statistics.acc_all === undefined || statistics.acc_all === null)
			return undefined;

		setAccArr(
			Object.values(statistics.acc_all).map((elem) =>
				Number(elem.slice(0, -1)),
			),
		);

		// 인간 채점 데이터 전달
		// console.log('total', statistics.datas_all);
		// console.log(
		// 	'predict',
		// 	Object.entries(statistics.datas_all.predict).map((elem: any) =>
		// 		Object.values(elem[1]).reduce((prev: any, cur: any) => prev + cur, 0),
		// 	),
		// );
		// console.log(
		// 	'기계 총합',
		// 	Object.entries(statistics.datas_all.predict)
		// 		.map((elem: any) =>
		// 			Object.values(elem[1]).reduce((prev: any, cur: any) => prev + cur, 0),
		// 		)
		// 		.reduce((prev: any, cur: any) => prev + cur, 0),
		// );
		// console.log(
		// 	'사람',
		// 	Object.entries(statistics.datas_all.person).map((elem: any) =>
		// 		Object.values(elem[1]).reduce((prev: any, cur: any) => prev + cur, 0),
		// 	),
		// );
		// console.log(
		// 	'사람 총합',
		// 	Object.entries(statistics.datas_all.person)
		// 		.map((elem: any) =>
		// 			Object.values(elem[1]).reduce((prev: any, cur: any) => prev + cur, 0),
		// 		)
		// 		.reduce((prev: any, cur: any) => prev + cur, 0),
		// );

		setColumnChartData(Object.entries(statistics.datas_all.person));
		setConfusingMatrixData(statistics.datas_all);

		let maxValue = 0;
		Object.entries(Object.entries(statistics.datas_all.predict))?.forEach(
			(elem: any) => {
				const sectionMaxValue = Math.max(
					...Object.values(elem[1][1]).map(Number),
				);

				if (maxValue < sectionMaxValue) maxValue = sectionMaxValue;
			},
		);

		setGraphMaxAxisValue(maxValue);

		return undefined;
	}, [statistics]);

	// Refetch를 위한 Boolean
	useEffect(() => () => setReFetchDataBoolean(false), [reFetchDataBoolean]);

	// 언마운트될 때, 차트 데이터 로딩 확인 false로 변경
	useEffect(() => {
		if (isMainLoaded) {
			setTimeout(() => {
				// mainPageScrollHandle();
				saveScrollBoolToSession(MAIN_SCROLL_HANDLE, false);
			}, 1000);
		} else saveScrollBoolToSession(MAIN_SCROLL_HANDLE, false);
	}, [isMainLoaded]);

	// chart 크기 변경을 위한 resize 이벤트 핸들러 등록
	useEffect(() => {
		const tempFunc = () => {
			setDynamicInnerWidth(window.innerWidth);
		};
		window.addEventListener('resize', tempFunc);
	}, [dynamicInnerWidth]);

	useEffect(() => {
		if (
			moveToTable &&
			scrollHeight.current &&
			scrollHeight.current?.scrollHeight > 1860
		) {
			window.scrollTo({
				top: 1860,
				// behavior: 'smooth',
			});
		} else if (!moveToTable) {
			window.scrollTo({
				top: 0,
				// behavior: 'smooth',
			});
		}
	}, [scrollHeight.current?.scrollHeight, moveToTable]);

	// 특정 문항 정보 수정
	const handleEditBtn = (questionId: number) => {
		setShowModal(true);
		setEditQuestionId(questionId);
		setMode(WriteMode.update);
	};

	// 특정 문항을 삭제
	const handleDeleteItem = (questionId: number) => {
		const selectedData = fetchQuestionData.find(
			(elem) => +elem.id === questionId,
		);

		Swal.fire({
			icon: 'warning',
			html: `<p><span style="color:red;">${selectedData?.no}번</span> 문항을 정말 삭제하시겠습니까?</p>`,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: '예',
			cancelButtonText: '아니오',
			heightAuto: false,
		}).then((result) => {
			//  취소버튼 클릭 시, http 요청이 추가되어야 함.
			if (result.isConfirmed) {
				// Yes 버튼 클릭했을 때
				(async () => {
					try {
						const cookieHash = getCookie(USER_TOKEN);
						const res = await axios.delete(
							`/${BASE_URL}/${QUESTIONS}/${selectedData?.id}`,
							{
								headers: {
									Authorization: cookieHash,
								},
							},
						);

						const { data } = res.data;
						if (data.status === 'success') {
							setFetchQuestionData((prev) =>
								prev.filter((elem) => questionId === +elem.id),
							);
							Swal.fire({
								title: 'Deleted',
								text: '정상적으로 삭제되었습니다.',
								icon: 'success',
								heightAuto: false,
							});

							setReFetchDataBoolean(true);
							/** 애니메이션 처리 */
						}
					} catch (error: unknown) {
						const err = error as CustomError;
						console.error(err);
						Swal.fire({
							title: 'Error',
							text: `${err.message}`,
							icon: 'warning',
							heightAuto: false,
						});
					}
				})();
			}
		});
	};

	// 클릭 시, 전체 데이터 삭제
	const handleDeleteAll = () => {
		const data: any[] = [];
		const cookieHash = getCookie(USER_TOKEN);

		checkStatus.forEach((item, idx) => {
			if (item) {
				const { id, no } = fetchQuestionData[idx];
				data.push([id, no]);
			}
		});

		if (data.length > 0) {
			Swal.fire({
				icon: 'warning',
				html: `<p>선택한 <span style="color:red">${data.length}개</span>의 문항을<br/>정말 삭제하시겠습니까?</p>`,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: '예',
				cancelButtonText: '아니오',
				heightAuto: false,
			}).then((result) => {
				if (result.isConfirmed) {
					(async () => {
						try {
							await Promise.all(
								data.map((elem) =>
									fetch(`/${BASE_URL}/${QUESTIONS}/${elem[0]}`, {
										method: 'DELETE',
										headers: { Authorization: cookieHash },
									}).then((res) => {
										setReFetchDataBoolean(true);
										setCheckAll(false);
										// dispatch(moveToTable(true));
									}),
								),
							);
						} catch (e) {
							console.error(e);
						}
					})();

					setCheckStatus([]);
				}
			});
		} else {
			Swal.fire({
				title: '',
				text: `데이터를 선택해주세요.`,
				icon: 'warning',
				heightAuto: false,
			});
		}
	};

	// 클릭 시, 전체 엑셀 데이터 다운로드 시작
	const downloadExcelData = useCallback((qId: number, no: string) => {
		setExcelDownloadIdx(qId);
		setExcelDownloadStart(true);

		const cookieHash = getCookie(USER_TOKEN);

		/** axios 요청 취소 */
		const { CancelToken } = axios;
		const source = CancelToken.source();

		(async () => {
			try {
				const res: AxiosResponse = await axios.get(`/${BASE_URL}/${POSTS}/`, {
					params: {
						q_id: qId,
						page: 1,
						size: 0,
					},
					headers: {
						Authorization: cookieHash,
					},
					cancelToken: source.token,
				});

				if (res.status === 200 || res.status === 201) {
					const { results } = res.data.data;

					excelAllDownload('all', results, no);
					setTimeout(() => {
						setExcelDownloadIdx(-1);
						setExcelDownloadStart(false);
					}, 1000);
				}
			} catch (e: unknown) {
				if (axios.isCancel(e)) {
					console.error('Request', e);
				}
			}
		})();
	}, []);

	const clickChartModal = useCallback((no: string) => {
		dispatch(setSelectedStatisticsType(no));
		setShowChartModal(true);
	}, []);

	const clickUploadIcon = useCallback((id: number) => {
		saveQuestionToSession(id);
		setShowUploadModal(true);
	}, []);

	const clickCheckItem = (idx: number) =>
		setCheckStatus((prev) => {
			const tempCheckStatus = [...prev];
			tempCheckStatus[idx] = !tempCheckStatus[idx];
			return tempCheckStatus;
		});

	const keyControlCheckAll = useCallback(
		(idx: number) => (e: any) => {
			if (e.key === 'Space') {
				setCheckStatus((prev) => {
					const tempCheckStatus = [...prev];
					tempCheckStatus[idx] = !tempCheckStatus[idx];
					return tempCheckStatus;
				});
			}
		},
		[],
	);

	return (
		<div
			ref={scrollHeight}
			className={cls(
				'w-full h-full bg-card-theme mt-20 pt-20 transition-all duration-500 ease-in-out overflow-x-hidden',
				`${isMainLoaded ? ' translate-y-0' : 'opacity-0 -translate-y-96'}`,
			)}
		>
			<div className="mb-10">
				<h1 className="sr-only">Question Page</h1>

				<div className="chart-width flex items-center justify-between min-h-[150px] mb-4">
					{!isMainLoaded && (
						<div>
							<Spinner text="Loading.." />
						</div>
					)}
					{isMainLoaded &&
						statistics.acc_all &&
						Object.entries(statistics.acc_all).map((elem, idx: number) => (
							<div
								key={elem[0]}
								className="flex w-[360px] 2xl:w-[400px] h-[170px] 2xl:h-[220px] rounded-lg shadow-xl bg-white relative mr-5 last:mr-0"
							>
								<span className="pl-2 pt-2 2xl:pl-4 2xl:pt-4 text-black/60 font-bold xl:text-[18px]">
									전체 {elem[0]} 정확도
								</span>
								<div className="flex justify-center items-center w-5/12 2xl:w-6/12 absolute top-5 right-5 2xl:top-5 p-3">
									<CircularProgressbar
										value={accArr[idx]}
										strokeWidth={12}
										text={`${accArr[idx] === undefined ? 0 : accArr[idx]}%`}
										styles={buildStyles({
											// Rotation of path and trail, in number of turns (0-1)
											rotation: 0,

											// Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
											strokeLinecap: 'butt',

											// Text size
											textSize: '20px',

											// How long animation takes to go from one percentage to another, in seconds
											pathTransitionDuration: 0.5,

											// Can specify path transition in more detail, or remove it entirely
											pathTransition: 'stroke-dashoffset 3s ease 0s',

											// Colors
											pathColor: `#1592e5`,
											textColor: '#1592e5',
											trailColor: '#d6d6d6',
											backgroundColor: '#3e98c7',
										})}
									/>
								</div>
							</div>
						))}
				</div>

				<div className="chart-width mt-8 mb-10">
					<h2 className="font-bold text-[18px]">인간 채점 점수 분포</h2>
					<div className="flex justify-center min-h-[350px]">
						{isMainLoaded &&
							columnChartData.map((elem: any, idx) => (
								<ConfusionColumnChart
									idx={idx}
									key={elem[0]}
									title={elem[0]}
									allData={elem[1]}
									xData={Object.keys(elem[1])}
									yData={Object.values(elem[1])}
									graphMaxAxisValue={graphMaxAxisValue}
									width={
										idx === 2
											? (dynamicInnerWidth *
													(dynamicInnerWidth > 1536 ? 0.8 : 0.9)) /
											  3
											: (dynamicInnerWidth *
													(dynamicInnerWidth > 1536 ? 0.8 : 0.9)) /
													3 -
											  12
									}
								/>
							))}
					</div>
				</div>
			</div>

			<div className="chart-width h-full mt-5 mb-10 min-h-[500px]">
				{isMainLoaded && confusingMatrixData !== undefined && (
					<>
						<h2 className="font-bold text-[18px]">
							기계, 인간 채점 점수 비교 Matrix
						</h2>
						<div className="relative flex justify-center">
							{[DataType.과제, DataType.전달, DataType.언어].map(
								(elem: string, idx) => (
									<ConfusionMatrix
										idx={idx}
										key={elem}
										title={elem}
										xData={[0, 1, 2, 3, 4].map(String)}
										yData={[4, 3, 2, 1, 0].map(String)}
										allData={[...statistics.mat[elem]].reverse()}
										width={
											idx === 2
												? (dynamicInnerWidth *
														(dynamicInnerWidth > 1536 ? 0.8 : 0.9)) /
												  3
												: (dynamicInnerWidth *
														(dynamicInnerWidth > 1536 ? 0.8 : 0.9)) /
														3 -
												  12
										}
										height={500}
									/>
								),
							)}
						</div>
					</>
				)}
			</div>

			<div className="w-[80%] mx-auto h-full mt-5 min-h-[500px]">
				<h2 className="font-bold text-[18px]">
					전체 문항의 기계, 인간 채점의 일치도
				</h2>
				{isMainLoaded && scatterData.length > 0 && scatterData !== undefined && (
					<>
						<div className="relative w-full">
							<div className="w-full h-[500px]">
								{statistics.scores_by_no && (
									<SwarmPlot scoreData={statistics.scores_by_no} />
								)}
							</div>
						</div>
					</>
				)}
			</div>

			<div className="chart-width flex justify-between mb-2 mt-16">
				<div>
					<TableButton
						clickHandlers={() => {
							setCheckAll((prev) => !prev);
						}}
					>
						전체선택
					</TableButton>
				</div>

				<div>
					<TableButton
						clickHandlers={() => {
							setShowModal((prev) => !prev);
							setMode(WriteMode.create);
						}}
					>
						문항 추가
					</TableButton>
					<TableButton
						isDelete
						className="ml-1"
						clickHandlers={() => handleDeleteAll()}
					>
						선택 삭제
					</TableButton>
				</div>
			</div>
			<div className="chart-width shadow-xl rounded-lg">
				<div className="relative w-full rounded-lg">
					<table className="table w-full border-0 rounded-lg bg-white">
						<thead className="bg-slate-200">
							<tr className="align-middle text-[18px]">
								<th
									className="hover:cursor-pointer rounded-tl-lg"
									onClick={() => setCheckAll((prev) => !prev)}
								>
									<CheckBox isChecked={checkAllButton} />
								</th>
								<th>
									<span className="block min-w-[40px]">번호</span>
								</th>
								<th className="border">문항</th>
								<th className="min-w-[120px]">수정일</th>
								<th className="min-w-[95px]">차트</th>
								<th>
									<span>동작</span>
								</th>
								<th className="rounded-tr-lg">
									<span>엑셀</span>
								</th>
							</tr>
						</thead>
						<tbody className="w-full h-full">
							{fetchQuestionData.length >= 0 &&
								fetchQuestionData.map((elem, idx) => (
									<tr className="w-full border text-[16px]" key={elem.id}>
										<td className="">
											<div
												role="button"
												tabIndex={0}
												className="flex w-full h-full justify-center items-center"
												onClick={() => clickCheckItem(idx)}
												onKeyUp={(e: any) => keyControlCheckAll(idx)}
											>
												<CheckBox isChecked={checkStatus[idx]} />
											</div>
										</td>

										<td>
											<span className="flex justify-center items-center">
												{elem.no}
											</span>
										</td>

										<td className="h-[20px] hover:bg-main-blue/20 text-[18px]">
											<button
												className="w-full h-full flex justify-start items-center"
												type="button"
												onClick={() => {
													saveQuestionToSession(elem.id);
													saveQuestionInfoToSession(elem);
													dispatch(setMoveToTable(true));
													dispatch(setPageInit());
													navigate(`/${QUESTION_LIST}/${elem.id}/${POSTS}`);
												}}
											>
												<p className="text-justify px-4">{elem.text}</p>
											</button>
										</td>
										{/* no에 대해 데이터가 없는 경우에는 비활성화 처리를 해준다. */}
										{/* 그 외에 데이터를 보내준다. */}

										<td className="">
											<div className="h-[30px] text-[14px] px-3 flex justify-center items-center">
												<span className="min-w-[140px] flex justify-center items-center">
													{elem.updated_at && dateString(elem.updated_at)}
												</span>
											</div>
										</td>

										<td>
											<div className="flex justify-center items-center p-2 pb-0">
												<IconTextBtn
													containerStyle="text-main-blue/50 hover:cursor-pointer hover:text-main-blue/100 p-1"
													iconType="chart"
													iconSize={35}
													text="문항별통계"
													textStyle="block text-main-blue text-[14px]"
													clickHandlers={() => clickChartModal(elem.no)}
												/>
											</div>
										</td>

										<td className="min-w-[80px]">
											<div className="p-2 pb-0 flex justify-center items-center">
												<IconTextBtn
													containerStyle="mr-2 text-main-blue/50 hover:cursor-pointer hover:text-main-blue/100"
													iconType="edit"
													iconSize={35}
													text="수정"
													textStyle="block text-main-blue text-[14px]"
													clickHandlers={() => handleEditBtn(elem.id)}
												/>

												<IconTextBtn
													containerStyle="text-red-400/50 hover:cursor-pointer hover:text-red-400"
													iconType="delete"
													iconSize={35}
													text="삭제"
													textStyle="block text-red-400 text-[14px]"
													clickHandlers={() => {
														handleDeleteItem(elem.id);
													}}
												/>
											</div>
										</td>
										<td className="min-w-[130px]">
											<div className="p-2 pb-0 flex justify-center items-center">
												<IconTextBtn
													iconType="excel"
													iconSize={35}
													iconStyle="mx-auto"
													text="업로드"
													textStyle="block text-[14px]"
													clickHandlers={() => clickUploadIcon(elem.id)}
												/>

												{excelDownloadStart && elem.id === excelDownloadIdx ? (
													<div className="min-w-[65px] flex justify-center items-center">
														<Oval
															height={35}
															width={35}
															strokeWidth={5}
															strokeWidthSecondary={4}
															visible
															color="#2297F4"
															ariaLabel="oval-loading"
															secondaryColor="#8FCAF9"
														/>
													</div>
												) : (
													<IconTextBtn
														containerStyle="min-w-[65px]"
														iconType="excel"
														iconSize={35}
														iconStyle="mx-auto"
														text="다운로드"
														textStyle="block text-[14px]"
														clickHandlers={() =>
															downloadExcelData(elem.id, elem.no)
														}
													/>
												)}
											</div>
										</td>
									</tr>
								))}
						</tbody>
					</table>

					{showModal && (
						<QuestionModal
							selectQuestionData={
								fetchQuestionData.filter(
									(elem) => +elem.id === editQuestionId,
								)[0]
							}
							setShowModal={setShowModal}
							mode={mode}
							reFetchDataBoolean={reFetchDataBoolean}
							setReFetchDataBoolean={setReFetchDataBoolean}
							setFetchQuestionData={setFetchQuestionData}
						/>
					)}

					{showUploadModal && (
						<ExcelModal
							setShowUploadModal={setShowUploadModal}
							setReFetchDataBoolean={setReFetchDataBoolean}
						/>
					)}

					{showChartModal && (
						<ChartModal
							setShowChartModal={setShowChartModal}
							statistics={statistics}
						/>
					)}
				</div>
			</div>
			<Footer className=" bg-[#444444] h-28" />
		</div>
	);
}
