/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';

// component
// import { HeatmapChart } from '@toast-ui/react-chart';
import { HeatmapChart } from '@toast-ui/chart';

// css
import '@toast-ui/chart/dist/toastui-chart.min.css'; // Chart 스타일
import { HeatmapChartOptions } from '@toast-ui/chart/types/options';
import { StatisticsKindData } from '@/types';

interface Props {
	idx: number | string;
	title?: string;
	xData?: string[];
	yData?: string[];
	allData: number[][];
	width: number;
	height: number;
}

function ConfusingMatrix({
	idx,
	title,
	xData,
	yData,
	allData,
	width,
	height,
}: Props) {
	useEffect(() => {
		const options: HeatmapChartOptions = {
			chart: {
				title: {
					text: `${title}`,
					offsetY: -5,
					align: 'center',
				},
				width: 'auto',
				height: 'auto',
			},
			series: {
				dataLabels: {
					visible: true,
				},
			},
			xAxis: {
				title: 'Predictions',
			},
			yAxis: {
				title: 'Actuals',
			},

			legend: {
				align: `bottom`,
			},
			tooltip: {
				formatter: (value, tooltipInfo) => `${tooltipInfo} ${value}개`,
				template: (model, defaultTooltipTemplate, theme) => {
					// console.log('body', body);
					// console.log('header', header);
					const { background } = theme;
					const [person, predict] = model.data[0].label.split(', ');

					return `
                <div class="toastui-chart-tooltip-category" style="font-weight: bold; font-family: Verdana, sans-serif; font-size: 13px; color: #ffffff; background:${background}">
                  예측 : ${predict}
                  실제 : ${person}
                </div>
                
                <div class="toastui-chart-tooltip-series-wrapper" style="font-weight: normal; font-family: Verdana, sans-serif; font-size: 12px; color: #ffffff; background:${background}">
                  <div class="toastui-chart-tooltip-series">
                    <span class="toastui-chart-series-name">
                      <i class="toastui-chart-icon" style="background: #a990b3"></i>
                      <span class="toastui-chart-name">${model.data[0].value}개</span>
                    </span>
                  </div>
                </div>`;
				},
			},
			theme: {
				chart: {
					fontFamily: 'Verdana',
				},
				series: {
					dataLabels: {
						fontWeight: '400',
						color: '#f5f5f5',
					},

					// 조금 나음
					// startColor: '#C4E0E5',
					// endColor: '#4CA1AF',

					// blue 계열
					// startColor: '#1CB5E0',
					// endColor: '#000046',

					// blue skies
					// startColor: '#56CCF2',
					// endColor: '#2F80ED',

					// purplepine
					// startColor: '#cbb4d4',
					// endColor: '#20002c',

					// azure lane
					// startColor: '#91EAE4',
					// endColor: '#86A8E7',

					// moon purple
					// startColor: '#8f94fb',
					// endColor: '#4e54c8',

					// blue
					startColor: '#A4BFEF',
					endColor: '#045DE9',
				},
				title: {
					fontSize: 20,
					fontWeight: 400,
					color: 'rgba(0,0,0,0.7)',
				},
				xAxis: {
					title: {
						color: 'rgba(0,0,0,0.5)',
					},
				},
				yAxis: {
					title: {
						color: 'rgba(0,0,0,0.5)',
					},
				},
				legend: {
					label: {
						color: 'rgba(255,255,255)',
					},
				},
			},
		};

		const data = {
			categories: {
				x: xData as string[],
				y: yData as string[],
			},

			series: allData,
		};

		const el = document.getElementById(`confusingMatrix_${idx}`);
		if (el === null) return undefined;

		const confusingMatrix = new HeatmapChart({ el, data, options });

		return () => {
			confusingMatrix.destroy();
		};
	}, []);

	return (
		<div
			id={`confusingMatrix_${idx}`}
			className={`${idx === 2 ? '' : ' mr-3'}`}
			style={{
				width,
				height,
			}}
		/>
	);
}

ConfusingMatrix.defaultProps = {
	title: 'TETSTSDFSDF',
	xData: ['1', '2', '3', '4', '5'],
	yData: ['5', '4', '3', '2', '1'],
};

export default ConfusingMatrix;
