/* eslint-disable react/no-string-refs */
import React, { useState, useEffect, useRef } from 'react';

import Chart, { RadarChartOptions } from '@toast-ui/chart';

// redux
import { useRedux } from '@/hooks';
import { DataType } from '@/utils';

interface Props {
	// selectedStatisticsNum: number;
	// scores: Array<any>;
	// idx: number | string;
	title?: string;
	width?: number;
	height?: number;
}

export default function DetailRadarChart({ title, width, height }: Props) {
	//   {
	// 	selectedStatisticsNum,
	// 	idx,
	// 	scores,
	// 	title,
	// 	width,
	// 	height,
	// }: Props
	const { predicts, personScores } = useRedux('detail');
	const { isThreePointAnswer } = useRedux('chart');

	useEffect(() => {
		// if (!personScores || !predicts) return undefined;
		const emptyArr: number[] = [0, 0, 0];

		const digit = isThreePointAnswer ? 33.3 : 25;

		const data = {
			categories: [DataType.과제, DataType.전달, DataType.언어],
			series: [
				{
					name: '기계채점',
					data: !predicts
						? emptyArr
						: [
								Number.isNaN(predicts[DataType.과제] * digit)
									? 0
									: Math.round(predicts[DataType.과제] * digit),
								Number.isNaN(predicts[DataType.전달] * digit)
									? 0
									: Math.round(predicts[DataType.전달] * digit),
								Number.isNaN(predicts[DataType.언어] * digit)
									? 0
									: Math.round(predicts[DataType.언어] * digit),
						  ],
				},
				{
					name: '인간채점',
					data: !personScores
						? emptyArr
						: [
								Number.isNaN(personScores[DataType.과제] * digit)
									? 0
									: Math.round(personScores[DataType.과제] * digit),
								Number.isNaN(personScores[DataType.전달] * digit)
									? 0
									: Math.round(personScores[DataType.전달] * digit),
								Number.isNaN(personScores[DataType.언어] * digit)
									? 0
									: Math.round(personScores[DataType.언어] * digit),
						  ],
				},
			],
		};

		const options: RadarChartOptions = {
			tooltip: {
				formatter: (value, tooltipDataInfo) => `${value}점`,
			},
			chart: {
				title: {
					text: `${title}`,
					align: 'center',
				},
				animation: {
					duration: 3000,
				},
				width: 'auto',
				height: 'auto',
			},
			series: {
				showDot: true,
				showArea: true,
				selectable: true,
			},
			exportMenu: {
				visible: false,
			},
			legend: {
				visible: true,
				align: 'bottom',
				showCheckbox: false,
			},

			theme: {
				series: {
					colors: ['#1492E5', '#F6A632'],
				},
				chart: {
					fontFamily: 'Verdana',
				},
				title: {
					fontSize: 20,
					fontWeight: 400,
					color: 'rgba(0,0,0,0.7)',
				},
			},
		};

		const el = document.getElementById(`detailRadar`);
		if (el === null) return undefined;

		const detailRadar = Chart.radarChart({ el, data, options });

		return () => {
			detailRadar.destroy();
		};
	}, [predicts, personScores, isThreePointAnswer]);

	return (
		<>
			<div
				key="detailRadarChart"
				id="detailRadar"
				className=""
				style={{
					width,
					height,
				}}
			/>
			{/* )} */}
		</>
	);
}

DetailRadarChart.defaultProps = {
	title: '',
	width: 600,
	height: 350,
};
