import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@/app/store';
import type { PostData } from '@/types';

interface PostState {
	checkedDataArr: number[];
	fetchPostDataArr: PostData[];
	fetchPostDataArrForGrid: PostData[];
	isLoadingPostData: boolean;
	renderState: boolean;
	page: {
		fetchPostCnt: number;
		currentPage: number;
		currentIdx: number;
		totalPage: number;
		itemSize: number;
		defaultItemSize: number;
	};
}

const initialState: PostState = {
	checkedDataArr: [],
	fetchPostDataArr: [],
	fetchPostDataArrForGrid: [],
	isLoadingPostData: false,
	renderState: false,
	page: {
		fetchPostCnt: 0,
		currentPage: 1,
		currentIdx: 0,
		totalPage: 0,
		itemSize: 0,
		defaultItemSize: 12,
	},
};

export const postSlice = createSlice({
	name: 'post',
	initialState,
	reducers: {
		saveCheckedArrToRedux: (
			state,
			{ payload }: PayloadAction<Array<number>>,
		) => {
			state.checkedDataArr = payload;
		},
		saveFetchPostDataToRedux: (
			state,
			{ payload }: PayloadAction<Array<PostData>>,
		) => {
			state.fetchPostDataArr = payload;
			state.fetchPostDataArrForGrid = payload.slice(0, payload.length - 1);
		},
		saveFetchPostCnt: (state, { payload }: PayloadAction<number>) => {
			state.page.fetchPostCnt = payload;
			const { itemSize, fetchPostCnt, defaultItemSize } = state.page;
			state.page.totalPage = Math.ceil(
				itemSize === 0
					? fetchPostCnt / defaultItemSize
					: fetchPostCnt / itemSize,
			);
		},
		saveCurrentPage: (state, { payload }: PayloadAction<number>) => {
			// 선택한 페이지를 currentpage에 저장
			console.log('prev', state.page.currentPage);
			state.page.currentPage = payload;
			console.log('change', state.page.currentPage);
		},
		increasePageIdx: (state) => {
			// currentIdx에서 +1 한 범위에 totalPage보다 크다면 return한다.
			if ((state.page.currentIdx + 1) * 10 + 1 > state.page.totalPage) return;
			state.page.currentIdx += 1;
		},
		decreasePageIdx: (state) => {
			// currentIdx를 뺀 값이 0보다 작을 경우, return
			if (state.page.currentIdx <= 0) return;
			state.page.currentIdx -= 1;
		},
		setRenderState: (state, { payload }: PayloadAction<boolean>) => {
			state.renderState = payload;
		},
		setIsLoadingPostData: (state, { payload }: PayloadAction<boolean>) => {
			state.isLoadingPostData = payload;
		},
		setPageInit: (state) => {
			state.page.currentPage = 1;
			state.page.currentIdx = 0;
		},
	},
});

export const {
	saveCheckedArrToRedux,
	saveFetchPostDataToRedux,
	saveFetchPostCnt,
	saveCurrentPage,
	increasePageIdx,
	decreasePageIdx,
	setRenderState,
	setIsLoadingPostData,
	setPageInit,
} = postSlice.actions;

export const selectCount = (state: RootState) => state.post;
export default postSlice.reducer;
