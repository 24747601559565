import React, { useState, useEffect } from 'react';
import { ResponsiveSwarmPlotCanvas } from '@nivo/swarmplot';
import { ScoreData, ScoresData, StudentId } from '@/types';
import { DataType } from '@/utils';

interface Props {
	scoreData: ScoresData[];
	indivisual?: boolean;
}

interface ChartType {
	id: string;
	group: string;
	studentId: number;
	volume: number;
}

const circleSize = 30;
const defaultCircleSize = 15;

function SwarmPlot({ scoreData, indivisual }: Props) {
	const [chartData, setChartData] = useState<ChartType[]>([]);
	const [totalDataCnt, setTotalDataCnt] = useState<number>(0);

	useEffect(() => {
		if (!scoreData) return undefined;
		const tempChartData: ChartType[] = [];
		const studentId: { [key: string]: number } = {
			0: 0,
			1: 0,
			2: 0,
			3: 0,
			4: 0,
		};

		scoreData.forEach((data) => {
			const autoData: ScoreData = data.auto;
			const personData: ScoreData = data.persons;
			// 과제 전달 언어

			[DataType.과제, DataType.전달, DataType.언어].forEach((category) => {
				autoData[category].forEach((score: number, idx: number) => {
					const personDataScore = personData[category][idx];
					const studentIdCategory = studentId;

					// 기계 채점 기준으로 인간 채점과 점수가 같은 것만 색깔을 파랑색으로 해준다.
					tempChartData.push({
						id: `${score === personDataScore ? 'true' : 'false'}`,
						group: score.toString(),
						studentId: (studentIdCategory[score] += 1),
						volume: indivisual ? circleSize : defaultCircleSize,
					});

					setTotalDataCnt((prev) => prev + 1);
				});
			});
			setChartData(tempChartData);
		});

		return () => {
			setChartData([]);
			setTotalDataCnt(0);
		};
	}, [scoreData]);

	return (
		chartData && (
			<ResponsiveSwarmPlotCanvas
				key={totalDataCnt}
				data={chartData}
				groups={['0', '1', '2', '3', '4']}
				id="id"
				value="studentId"
				valueFormat="$.2f"
				valueScale={{ type: 'linear', min: 0, reverse: false }}
				// size={{ key: 'volume', values: [4, 20], sizes: [4, 8] }}
				size={6}
				spacing={2}
				simulationIterations={60}
				colors={['#FEDEDE', '#3F81EC']}
				colorBy="id"
				borderColor={{
					from: 'color',
					modifiers: [['darker', 0.6]],
				}}
				margin={{ top: 80, right: 100, bottom: 80, left: 100 }}
				axisTop={{
					// orient: 'top',

					tickSize: 10,
					tickPadding: 10,
					tickRotation: 0,
					// legend: 'Swarm Plot',
					legend: `전체 문항 개수 : ${totalDataCnt}`,
					legendPosition: 'middle',
					legendOffset: -56,
				}}
				axisRight={{
					// orient: 'right',
					tickSize: 10,
					tickPadding: 5,
					tickRotation: 0,
					legend: 'Number of questions',
					legendPosition: 'middle',
					legendOffset: 76,
				}}
				axisBottom={{
					// orient: 'bottom',
					tickSize: 25,
					tickPadding: 5,
					tickRotation: 0,
					legend:
						'기계채점 기준 - 인간 채점 점수와 일치한다면 파랑색, 일치하지 않는다면 분홍색',
					legendPosition: 'middle',
					legendOffset: 65,
				}}
				axisLeft={{
					// orient: 'left',
					tickSize: 10,
					tickPadding: 5,
					tickRotation: 0,
					legend: 'Number of questions',
					legendPosition: 'middle',
					legendOffset: -76,
				}}
				theme={{ background: 'white' }}
				// useMesh
			/>
		)
	);
}

export default React.memo(SwarmPlot);

SwarmPlot.defaultProps = {
	indivisual: false,
};
