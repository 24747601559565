import React, { useCallback } from 'react';
import { ActionButton } from '@/components';

import {
	DeleteOutlined,
	EditOutlined,
	BarChartOutlined,
} from '@ant-design/icons';
import { HandlerFunc } from '@/types';

interface IconTextBtnProps {
	containerStyle?: string;
	iconType: string;
	iconSize?: number;
	iconStyle?: string;
	text?: string;
	textStyle?: string;
	clickHandlers?: HandlerFunc;
}

export default function IconTextBtn({
	containerStyle,
	iconType,
	iconSize,
	iconStyle,
	text,
	textStyle,
	clickHandlers,
}: IconTextBtnProps) {
	return (
		<ActionButton
			className={`opacity-50 hover:opacity-100${
				containerStyle === '' ? '' : ` ${containerStyle}`
			}`}
			onClick={clickHandlers}
		>
			<>
				{iconType === 'chart' ? (
					<BarChartOutlined
						className={iconStyle}
						style={{ fontSize: `${iconSize}px` }}
					/>
				) : null}
				{iconType === 'edit' ? (
					<EditOutlined
						className={iconStyle}
						style={{ fontSize: `${iconSize}px` }}
					/>
				) : null}
				{iconType === 'delete' ? (
					<DeleteOutlined
						className={iconStyle}
						style={{ fontSize: `${iconSize}px` }}
					/>
				) : null}
				{iconType === 'excel' ? (
					<img
						className={iconStyle}
						style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
						src="./excel.png"
						alt="excel-icon"
					/>
				) : null}

				<span className={textStyle}>{text}</span>
			</>
		</ActionButton>
	);
}

// export default React.memo(IconTextBtn);

IconTextBtn.defaultProps = {
	containerStyle: '',
	iconStyle: '',
	iconSize: 30,
	text: 'default',
	textStyle: '',
	clickHandlers: () => null,
};
