import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState = {
	isLogin: false,
	test: false,
};

export const loginSlice = createSlice({
	name: 'login',
	initialState,
	reducers: {
		toggleIsLogin: (state) => {
			state.isLogin = !state.isLogin;
		},
		setIsLogin: (state, action: PayloadAction<boolean>) => {
			state.isLogin = action.payload;
		},
	},
});

export const { toggleIsLogin, setIsLogin } = loginSlice.actions;
export default loginSlice.reducer;
