import React, { useState, useEffect } from 'react';

/** component */
import { DetailExplanationItem } from '@/components';

/** redux */
import {
	toggleScoreDetailVisible,
	onSelectedFeatureItem,
} from '@/app/reducer/detailpage/detailSlice';
import { useDispatch } from 'react-redux';
import { useRedux } from '@/hooks';
import { DataType } from '@/utils';

export default function DetailExplanation() {
	const dispatch = useDispatch();
	const { features, predicts, personScores, selectedFeatureItem } =
		useRedux('detail');

	const handleSelectedItem = (data: any) => {
		if (JSON.stringify({}) === JSON.stringify(selectedFeatureItem)) {
			dispatch(onSelectedFeatureItem(data));
		} else if (
			JSON.stringify({}) !== JSON.stringify(selectedFeatureItem) &&
			JSON.stringify(data) !== JSON.stringify(selectedFeatureItem)
		) {
			dispatch(onSelectedFeatureItem(data));
		} else dispatch(onSelectedFeatureItem({}));
	};

	return (
		<div className="outline-side-navy h-[calc(100vh-334px)] relative overflow-scroll overflow-x-hidden overflow-y-auto relative">
			{['과제', '언어', '전달', '한글 맞춤법']
				.filter((key: any) => predicts[key] !== null)
				.map((key: any) => {
					let person = 0;

					if (key === DataType.과제) person = personScores?.과제 || 0;

					if (key === DataType.전달) person = personScores?.전달 || 0;

					if (key === DataType.언어) person = personScores?.언어 || 0;

					return (
						<DetailExplanationItem
							key={key}
							title={key}
							data={{
								feature:
									key === '한글 맞춤법' ? features['과제'] : features[key],
								predict: predicts[key],
								person,
							}}
							handleSelectedItem={handleSelectedItem}
						/>
					);
				})}
			{/* <button
				className="absolute -top-10 right-0"
				type="button"
				onClick={() => dispatch(toggleScoreDetailVisible())}
			>
				<span className="h-5 flex justify-center items-center px-[0.5rem] py-[0.1rem] text-black/50 font-bold">
					상세창 닫기 X
				</span>
			</button> */}
		</div>
	);
}
