import React, { useEffect, useRef, useState } from 'react';

/** component */
import { InputBox } from '@/components';
import { useRedux } from '@/hooks';

interface IDetailExplanationItem {
	title: string;
	data: IDataItem;
	handleSelectedItem: (data: any) => void;
}

interface IDataItem {
	predict: number;
	feature: any;
	person?: number;
}

export default function DetailExplanationItem({
	title,
	data,
	handleSelectedItem,
}: IDetailExplanationItem) {
	// Keywords가 있는지 확인하는 변수
	const isEmpty = useRef<boolean>(false);
	const searchKeywordCnt = useRef<number>(0);
	const [isEmptyDoReRender, setIsEmptyDoReRender] = useState<boolean>(false);
	const { selectedFeatureItem } = useRedux('detail');

	// useState로 바꿔주어야 함.
	useEffect(() => {
		isEmpty.current = false;
		searchKeywordCnt.current = 0;

		const searchKeywordArr = Object.keys(data.feature).map((root: any) =>
			Object.keys(data.feature[root]).filter(
				(key) =>
					key.split('').slice(1, key.indexOf(')')).join('') ===
						(title === '전달' ? '내용' : title) &&
					data.feature[root][key][0].searchs.length > 0,
			),
		);

		searchKeywordCnt.current = searchKeywordArr
			.map((elem) => elem.length)
			.reduce((prev, cur) => prev + cur, 0);

		if (searchKeywordCnt.current === 0) isEmpty.current = true;
	}, [data.feature]);

	useEffect(() => {
		if (isEmpty.current) {
			setIsEmptyDoReRender(true);
		} else setIsEmptyDoReRender(false);
	}, [isEmpty.current]);

	return (
		<div className="mb-3">
			<div className="flex items-center justify-between bg-side-navy text-white px-1 rounded-t-lg">
				<div className="flex flex-col basis-5/12 justify-center h-[74px]">
					<span className="text-[15px] px-2">{title} 점수</span>
					<span className="text-[15px] px-2">ESSAY SCORE</span>
				</div>

				<div className="flex ml-2">
					{title === '한글 맞춤법' ? (
						''
					) : (
						<>
							<InputBox
								type="span"
								containerClass="flex justify-center items-center"
								keyText="기계채점"
								keyClass="text-white font-bold flex items-center text-[18px]"
								valText={data && data.predict?.toString()}
								valClass="flex items-center rounded-full justify-center bg-white my-1 mx-3 w-[23px] h-[23px] text-main-blue font-bold text-lg text-[18px]"
							/>
							<InputBox
								type="span"
								containerClass="flex justify-center items-center"
								keyText="인간채점"
								keyClass="text-main-orange font-bold flex items-center text-[18px]"
								valText={data && data.person?.toString()}
								valClass="flex items-center rounded-full justify-center bg-white my-1 mx-3 w-[23px] h-[23px] text-main-orange font-bold text-lg text-[18px]"
							/>
						</>
					)}
				</div>
			</div>

			<ul className="border border-side-navy">
				{isEmptyDoReRender ? (
					<span className="flex justify-center items-center p-2">
						검색된 자질이 없습니다.
					</span>
				) : (
					Object.keys(data.feature).map((root: string) =>
						Object.keys(data.feature[root])
							.filter(
								(key) =>
									key.split('').slice(1, key.indexOf(')')).join('') ===
										(title === '전달' ? '내용' : title) &&
									data.feature[root][key][0].searchs.length > 0,
							)
							.map((key) => {
								const item = data.feature[root];

								const searchs = [
									...new Set(
										item[key][0].searchs.map((x: any) => {
											if (typeof x !== 'string') return x.join('').trim();
											return x.trim();
										}),
									),
								];

								const colors = ['bg-red-400'];
								const random = Math.floor(Math.random() * colors.length);
								return (
									<li
										role="presentation"
										key={key}
										className={`flex flex-col w-full hover:bg-red-200 hover:cursor-pointer text-[18px] ${
											selectedFeatureItem.key === key + title
												? selectedFeatureItem.color
												: ''
										}`}
										onMouseDown={() =>
											handleSelectedItem({
												key: key + title,
												...item[key][0],
												// 임시삭제
												// color: colors[random],
												color: 'white',
											})
										}
									>
										<div className="flex justify-between items-center m-1">
											<div className="flex justify-center items-center mr-3">
												<span className="inline-block max-w-[170px] mx-2">
													{title === '전달'
														? root
																.split(' ')
																.slice(1)
																.join(' ')
																.replaceAll('내용', '전달')
														: root
																.split(' ')
																.slice(title === '한글 맞춤법' ? 2 : 1)
																.join(' ')}
													{/* {root} */}
												</span>
												{/* 임시삭제 */}
												<span
													className={`text-main-blue font-bold m-1 rounded px-2 py-1 border ${
														selectedFeatureItem.key === key + title
															? 'bg-gray-100'
															: ''
													}`}
												>
													{searchs.length}
												</span>
											</div>
											<div className="w-8/12 border p-1 rounded bg-gray-100">
												<span className="text-[16px] leading-6">
													{item[key][0].desc.split(' ').slice(1).join(' ')}
												</span>
											</div>
										</div>
										{/* 임시삭제 */}
										{/* <div
											className={`flex flex-wrap justify-arround items-center m-1 ${
												selectedFeatureItem.key === key + title
													? 'visible'
													: 'hidden'
											}`}
										>
											{searchs.map((x: any) => (
												<div key={x} className="m-1 p-1">
													<span className="rounded p-1 bg-gray-500 text-white">
														{x}
													</span>
												</div>
											))}
										</div> */}
									</li>
								);
							}),
					)
				)}
			</ul>
		</div>
	);
}
