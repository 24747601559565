import React from 'react';

/** components */
import { PostHeader } from '@/components';

/** redux */
import {
	toggleCheckAll,
	toggleDeleteAll,
	toggleDeleteSelected,
} from '@/app/reducer/postpage/checkSlice';
import { useAppDispatch } from '@/app/hooks';

export default function PostHeaderContainer() {
	const dispatch = useAppDispatch();

	const handleCheckAll = () => {
		dispatch(toggleCheckAll());
	};
	const handleDeleteSelected = () => {
		dispatch(toggleDeleteSelected());
	};

	const handleDeleteAll = () => {
		dispatch(toggleDeleteAll());
	};

	return (
		<PostHeader
			handleCheckAll={handleCheckAll}
			handleDeleteSelected={handleDeleteSelected}
			handleDeleteAll={handleDeleteAll}
		/>
	);
}
