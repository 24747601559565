import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/** css */
import '@/style/page/notfound.scss';

/** type */
import type { Dispatch, SetStateAction } from 'react';
import { HOMEPAGE } from '@/utils/url';

interface Props {
	notFound: boolean;
	setNotFound: Dispatch<SetStateAction<boolean>>;
}

export default function NotFound({ notFound, setNotFound }: Props) {
	const [time, setTime] = useState(3);
	const navigate = useNavigate();

	useEffect(() => {
		setNotFound(true);

		return () => setNotFound(false);
	}, [notFound]);

	useEffect(() => {
		const id = setTimeout(() => {
			setTime((prev) => prev - 1);
		}, 1000);
		if (time <= 0) {
			navigate(-1);
		}
		return () => clearTimeout(id);
	}, [time]);

	function goToPreviousPage() {
		navigate(-1);
	}

	function goToMainPage() {
		navigate(`/${HOMEPAGE}`, { replace: true });
	}

	return (
		<section className="not-found">
			<div id="background" />
			<div className="top">
				<h1>404</h1>
				<h3>page not found</h3>
			</div>
			<div className="container">
				<div className="ghost-copy">
					<div className="one" />
					<div className="two" />
					<div className="three" />
					<div className="four" />
				</div>
				<div className="ghost">
					<div className="face">
						<div className="eye" />
						<div className="eye-right" />
						<div className="mouth" />
					</div>
				</div>
				<div className="shadow" />
			</div>
			<div className="bottom">
				<p>Boo, looks like a ghost stole this page!</p>
				<form className="search">
					<input type="text" className="search-bar" placeholder="Search" />
					<button type="submit" className="search-btn">
						<i className="fa fa-search" />
					</button>
				</form>
				<div className="buttons">
					<button
						type="button"
						className="btn"
						onClick={() => goToPreviousPage()}
					>
						Back
					</button>
					<button type="button" className="btn" onClick={() => goToMainPage()}>
						Main
					</button>
				</div>
				<p>{time}초뒤에 뒤로 이동합니다.</p>
			</div>

			<footer className="footer">
				<p>
					made by <a href="https://codepen.io/juliepark"> julie</a>{' '}
				</p>
			</footer>
		</section>
	);
}
