import React from 'react';

/** components */
import { ActionButton } from '../atomic';

interface UpdateWithCancelProps {
	okText: string;
	okClass: string;
	cancelText: string;
	cancelClass: string;
	handleOk: () => void;
	handleCancel: () => void;
}

export default function UpdateWithCancel({
	okText,
	okClass,
	cancelText,
	cancelClass,
	handleOk,
	handleCancel,
}: UpdateWithCancelProps) {
	return (
		<div>
			<ActionButton
				className={`${okClass}`}
				type="button"
				onClick={() => handleOk()}
			>
				<span>{okText}</span>
			</ActionButton>
			<ActionButton
				className={`${cancelClass}`}
				type="button"
				onClick={() => handleCancel()}
			>
				<span>{cancelText}</span>
			</ActionButton>
		</div>
	);
}
