/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

/** components */
import { Checkbox } from 'antd';
import { IconTextBtn } from '@/components';

/** css */
import 'antd/lib/checkbox/style/index.css';

/** utils */
import { dateString } from '@/utils';

/** type */
import { HandlerFunc, PostData } from '@/types';

/** redux */
import { useRedux } from '@/hooks';

interface PostItemProps {
	idx: number;
	postData: PostData;
	handleEditBtn?: HandlerFunc;
	handleChecked?: HandlerFunc;
	handleDeleteData?: HandlerFunc;
	handleKeyUpWhenEnter?: HandlerFunc;
}

export default function PostItem({
	idx,
	postData,
	handleEditBtn,
	handleChecked,
	handleDeleteData,
	handleKeyUpWhenEnter,
}: PostItemProps) {
	const { checkedDataArr } = useRedux('post');
	// eslint-disable-next-line camelcase
	const { id, title, content, updated_at, tester_id } = postData;
	const { page } = useRedux('post');
	const { currentPage } = page;

	return (
		<li className="flex flex-col items-center border rounded-lg p-5">
			<div className="h-[70px] w-full flex justify-between items-center mb-1">
				<div className="flex justify-center items-center">
					<button
						type="button"
						className="text-[12px] hover:text-main-blue "
						onClick={handleChecked}
						onKeyUp={handleKeyUpWhenEnter}
					>
						<Checkbox checked={checkedDataArr.includes(id)} />
						<span className="ml-5 text-[16px] 2xl:text-[18px] font-semibold">{`${
							(currentPage - 1) * 12 + idx + 1
						}`}</span>
					</button>
				</div>

				<div className="flex justify-between items-center text-[16px]">
					<IconTextBtn
						containerStyle="mr-3 text-main-blue/50 hover:cursor-pointer hover:text-main-blue/100"
						iconType="edit"
						iconSize={25}
						text="보기"
						textStyle="block text-main-blue text-[15px]"
						clickHandlers={handleEditBtn}
					/>
					<IconTextBtn
						containerStyle="text-red-400/50 hover:cursor-pointer hover:text-red-400"
						iconType="delete"
						iconSize={25}
						text="삭제"
						textStyle="block text-red-400 text-[15px]"
						clickHandlers={handleDeleteData}
					/>
					{/* <DeleteBtn
						className="relative flex flex-col items-center border border-transparent hover:border-red-400 hover:rounded-lg"
						onClick={handleDeleteData}
					/> */}
				</div>
			</div>

			<h2 className="pb-2 text-[18px] 2xl:text-[20px] w-full font-semibold m-0 border-b">
				{`응시자 ID: ${tester_id}`}
			</h2>
			<p className="post__content h-[6em] text-[16px] 2xl:text-[18px] w-full mt-3">
				{content}
			</p>

			<div className="flex w-full mt-5 justify-end">
				<span className="text-[13px]">{`updated: ${dateString(
					updated_at,
				)}`}</span>
			</div>
		</li>
	);
}

PostItem.defaultProps = {
	handleEditBtn: () => null,
	handleChecked: () => null,
	handleDeleteData: () => null,
	handleKeyUpWhenEnter: () => null,
};
