import React, { useEffect, useState } from 'react';

/** component */
import { Pagination } from '@/components';

/** redux */
import { useRedux } from '@/hooks';
import { useAppDispatch } from '@/app/hooks';
import {
	decreasePageIdx,
	increasePageIdx,
	saveCurrentPage,
} from '@/app/reducer/postpage/postSlice';

/** util */
import { handleScroll } from '@/utils';

export default function PaginationContainer() {
	const [isIncreIdx, setIsIncreIdx] = useState(true);
	const { page, fetchPostDataArr } = useRedux('post');
	const { currentIdx } = page;
	const dispatch = useAppDispatch();

	function handlePrev() {
		dispatch(decreasePageIdx());
		setIsIncreIdx(false);
		handleScroll();
	}

	function handlePage(e) {
		dispatch(saveCurrentPage(Number(e.target.textContent)));
		handleScroll();
	}

	function handleNext() {
		dispatch(increasePageIdx());
		setIsIncreIdx(true);
		handleScroll();
	}

	return (
		<Pagination
			page={page}
			fetchPostDataArr={fetchPostDataArr}
			handlePrev={(e) => handlePrev(e)}
			handlePage={(e) => handlePage(e)}
			handleNext={(e) => handleNext(e)}
		/>
	);
}
