export const QUESTION_ID = 'question-id';
export const QUESTION_INFO = 'question-info';
export const Q_CHECK_STATUS = 'question-check-status';
export const SCROLL_INDEX = 'scroll-index';

export function saveQuestionToSession(questionId: number) {
	window.sessionStorage.setItem(QUESTION_ID, questionId.toString());
}

export function getQuestionFromSession() {
	return window.sessionStorage.getItem(QUESTION_ID);
}

export function saveQuestionInfoToSession(questionInfo: object) {
	window.sessionStorage.setItem(QUESTION_INFO, JSON.stringify(questionInfo));
}

export function getQuestionInfoFromSession() {
	return JSON.parse(window.sessionStorage.getItem(QUESTION_INFO) || '{}');
}

export function saveCheckStatusToSession(key: string, value: any[]): void {
	window.sessionStorage.setItem(key, JSON.stringify(value));
}

/** 세션에 CheckStatus가 있는지 확인한다. 없으면 빈 배열의 값으로 초기화 해준다.
 * @param key
 * @returns
 */
export function getCheckStatusFromSession(key: string): [] {
	return JSON.parse(window.sessionStorage.getItem(key) || '[]');
}

export function saveScrollIndexToSession(indexArray: number[]) {
	window.sessionStorage.setItem(SCROLL_INDEX, JSON.stringify(indexArray));
}

export function getScrollIndexFromSession(key: string) {
	return JSON.parse(window.sessionStorage.getItem(key) || '[]');
}

export function saveScrollBoolToSession(key: string, value: boolean) {
	window.sessionStorage.setItem(key, JSON.stringify(value));
}

export function getScrollBooleanFromSession(key: string): boolean {
	return JSON.parse(window.sessionStorage.getItem(key) || 'false');
}
