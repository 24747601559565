import React, { useState, useEffect, useLayoutEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

/** utils */
import {
	USER_TOKEN,
	getCookie,
	BASE_URL,
	POSTS,
	getQuestionFromSession,
	handleScroll,
} from '@/utils';

/** components */
import { PostList } from '@/components';
import Swal from 'sweetalert2';

/** types */
import type { PostData } from '@/types';
import { useDispatch } from 'react-redux';
import { useRedux } from '@/hooks';

/** redux-reducer */
import {
	saveCheckedArrToRedux,
	saveFetchPostDataToRedux,
	saveFetchPostCnt,
	setRenderState,
	setIsLoadingPostData,
	setPageInit,
} from '@/app/reducer/postpage/postSlice';
import {
	setCheckAll,
	setDeleteAll,
	setDeleteSelected,
} from '@/app/reducer/postpage/checkSlice';
import { setInit } from '@/app/reducer/detailpage/detailSlice';

/** util */
// eslint-disable-next-line @typescript-eslint/no-var-requires
const debounce = require('lodash.debounce');

export default function PostListContainer() {
	const [dimension, setDimension] = useState(window.innerWidth);
	const { checkAll, deleteAll, deleteSelected } = useRedux('check');
	const { checkedDataArr, fetchPostDataArr, page, renderState } =
		useRedux('post');
	const { currentPage } = page;

	const { id } = useParams();
	const dispatch = useDispatch();

	// Pagenation cleanUp
	useEffect(
		() => () => {
			dispatch(setInit());
		},
		[],
	);

	useEffect(() => {
		handleScroll();
	}, []);

	/** resize 디바운스 */
	useEffect(() => {
		function logger() {
			setDimension(window.innerWidth);
			console.log('[viewport changed] =>', window.innerWidth);
		}
		const debounceCallback = debounce(logger, 400);

		window.addEventListener('resize', debounceCallback);

		return () => window.removeEventListener('resize', debounceCallback);
	}, [dimension]);

	/** data fetching */
	useEffect(() => {
		// if (!fetchPostDataArr.length) return;
		const cookieHash = getCookie(USER_TOKEN);

		/** axios 요청 취소 */
		const { CancelToken } = axios;
		const source = CancelToken.source();

		(async () => {
			try {
				const res: AxiosResponse = await axios.get(`/${BASE_URL}/${POSTS}/`, {
					params: {
						page: currentPage,
						size: 12,
						q_id: id,
					},
					headers: {
						Authorization: cookieHash,
					},
					cancelToken: source.token,
				});

				if (res.status === 200 || res.status === 201) {
					const { data } = res.data;

					dispatch(saveFetchPostDataToRedux(data.results));
					dispatch(saveFetchPostCnt(data.total_count));
					dispatch(setIsLoadingPostData(true));
				}
			} catch (e: unknown) {
				if (axios.isCancel(e)) {
					console.error('Request', e);
				}
			}
		})();

		// eslint-disable-next-line consistent-return
		return () => {
			source.cancel();
			dispatch(saveFetchPostDataToRedux([]));
			dispatch(saveFetchPostCnt(0));
			dispatch(setRenderState(false));
			dispatch(setIsLoadingPostData(false));
		};
	}, [currentPage, renderState]);

	/** 전체선택 버튼 처리 */
	useEffect(() => {
		let data;
		if (checkAll) {
			data = fetchPostDataArr.map((elem: PostData) => +elem.id);
			dispatch(saveCheckedArrToRedux(data));
		} else {
			dispatch(saveCheckedArrToRedux([]));
		}
	}, [checkAll]);

	/** 선택삭제 버튼 처리 */
	useEffect(() => {
		const checkedDataArrLength = checkedDataArr.length;

		if (deleteSelected && checkedDataArrLength > 0) {
			const targetTesterIdArr: any = [];
			checkedDataArr.forEach((element: any) => {
				fetchPostDataArr.forEach((test: any) => {
					if (element === test.id) {
						targetTesterIdArr.push(test.tester_id);
					}
				});
			});
			const targetIdArr = [...checkedDataArr];
			const cookieHash = getCookie(USER_TOKEN);

			Swal.fire({
				icon: 'warning',
				html: `<p>선택한 ${
					targetTesterIdArr.length
				}개 글<br/><span style="font-size:14px; color:red;">${targetTesterIdArr.join(
					', ',
				)}</span><br/>을 정말 삭제하시겠습니까?</p>`,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: '예',
				cancelButtonText: '아니오',
				heightAuto: false,
			}).then((result) => {
				//  취소버튼 클릭 시, http 요청이 추가되어야 함.
				if (result.isConfirmed) {
					const proceedData = [] as number[];
					const errorData = [] as number[];
					(async () => {
						try {
							await Promise.all(
								targetIdArr.map((target: number) =>
									fetch(`/${BASE_URL}/${POSTS}/${target}`, {
										method: 'DELETE',
										headers: { Authorization: cookieHash },
									}).then((res) => {
										const taget2 = Number(res.url.split('/').at(-1));
										if (res.ok) {
											proceedData.push(taget2);
										} else errorData.push(taget2);
									}),
								),
							);

							/**
							 * 만약 proceed, checked가 모두 일치한다면, 전체 삭제
							 * 1. errorData가 0보다 크다면 실패한 경우가 하나라도 발생한 경우이다.
							 * 이때 어떤 글 삭제에서 error가 발생했는지 알려준다.
							 */
							if (
								JSON.stringify(proceedData.sort()) ===
								JSON.stringify([...checkedDataArr].sort())
							) {
								dispatch(setRenderState(true));
								Swal.fire({
									title: 'Deleted',
									text: '정상적으로 삭제되었습니다.',
									icon: 'success',
									heightAuto: false,
								});
							} else {
								throw new Error('삭제 실패');
							}

							/** 삭제 처리된 데이터들을 프론트단 렌더링을 위해서 필터링처리 */
							dispatch(
								saveFetchPostDataToRedux(
									fetchPostDataArr.filter(
										(item: PostData) => !proceedData.includes(+item.id),
									),
								),
							);

							/** 정상적으로 삭제 처리된 데이터들의 CheckedData 자료구조에서 제외시켜준다.
							 * 만약 정상적으로 삭제되지 않은 데이터들은 Checked 된 상태로 남아있다.
							 */
							const checkedDatas: number[] = [...checkedDataArr];
							dispatch(
								saveCheckedArrToRedux(
									checkedDatas.filter((data) => !proceedData.includes(data)),
								),
							);

							/** 선택 해제  */
							dispatch(setCheckAll(false));
						} catch (e) {
							console.error(e);
							Swal.fire({
								title: 'Error',
								text: `${(e as Error).message}`,
								icon: 'error',
								heightAuto: false,
							});
						}
					})();
				}
			});
		} else if (deleteSelected && checkedDataArrLength === 0) {
			Swal.fire({
				title: '',
				text: `데이터를 선택해주세요.`,
				icon: 'warning',
				heightAuto: false,
			});
		}

		return () => {
			dispatch(setDeleteSelected(false));
		};
	}, [deleteSelected]);

	useEffect(() => {
		if (deleteAll) {
			const cookieHash = getCookie(USER_TOKEN);

			Swal.fire({
				icon: 'warning',
				html: `<p><span style="color:red;">모든 글</span>을 정말 삭제하시겠습니까?</p>`,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: '예',
				cancelButtonText: '아니오',
				heightAuto: false,
			}).then((result) => {
				//  취소버튼 클릭 시, http 요청이 추가되어야 함.
				if (result.isConfirmed) {
					(async () => {
						try {
							const res = await axios.delete(
								`/${BASE_URL}/${POSTS}/?q_id=${id}`,
								{
									headers: {
										Authorization: cookieHash,
									},
								},
							);

							if (res.data.status === 'success') {
								dispatch(setRenderState(true));
								Swal.fire({
									title: 'Deleted',
									text: '정상적으로 삭제되었습니다.',
									icon: 'success',
									heightAuto: false,
								});
							} else {
								throw new Error('전체삭제 작업을 실패했습니다.');
							}
						} catch (e) {
							console.error(e);
							Swal.fire({
								title: 'Error',
								html: `${(e as Error).message}`,
								icon: 'error',
								heightAuto: false,
							});
						}
					})();
				}
			});
		}
		return () => {
			dispatch(setDeleteAll(false));
		};
	}, [deleteAll]);

	return <PostList fetchPostDataArr={fetchPostDataArr} />;
}
