import React, { useEffect, useState, SetStateAction, Dispatch } from 'react';

// components
import {
	Portal,
	ConfusionColumnChart,
	CloseBtn,
	LineScatterDiff,
	ModalColumnLineChart,
} from '@/components';
import { Button } from 'antd';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// css
import './modal.scss';
import 'antd/lib/button/style/index.css';
import { ScatterScoreData, ScoresData, Statistics } from '@/types';

// redux
import { useRedux, useModal, useKeyEscape } from '@/hooks';

// util
import { cls } from '@/utils';
import SwarmPlot from '../chart/SwarmPlot';

type ModalControlProps = {
	setShowChartModal: Dispatch<SetStateAction<boolean>>;
	statistics: Statistics;
};

function ChartModal({ setShowChartModal, statistics }: ModalControlProps) {
	const [acc, setAcc] = useState<Array<Array<string>>>([]);
	const [predict, setPredict] = useState<Array<string | object>>();
	const [person, setPerson] = useState<Array<string | object>>();
	const [swarmPlotData, setSwarmPlotData] = useState<ScoresData[]>(
		[] as ScoresData[],
	);
	const [lineGraphMaxAxisValue, setlineGraphMaxAxisValue] = useState<number>(0);

	const { selectedStatisticsType } = useRedux('chart');

	useModal();

	useKeyEscape({ setShowChartModal });

	// 정확도 데이터
	useEffect(() => {
		const accuracy = statistics.acc_by_nos.filter(
			(elem: any) => elem.no === selectedStatisticsType,
		)[0];

		const renderAccData = Object.entries(accuracy).filter(
			(elem) => elem[0] !== 'no',
		);
		setAcc(renderAccData);

		const filteredWithStatisticsNumber: { [key: string]: any } =
			statistics.datas_by_no.filter(
				(elem: any) => elem.no === selectedStatisticsType.toString(),
			)[0];

		// console.log(filteredWithStatisticsNumber);
		setPredict(Object.entries(filteredWithStatisticsNumber.predict));
		setPerson(Object.entries(filteredWithStatisticsNumber.person));

		let maxValue = 0;
		Object.entries(filteredWithStatisticsNumber.predict)?.forEach(
			(elem: any) => {
				const sectionMaxValue = Math.max(...Object.values(elem[1]).map(Number));
				if (maxValue < sectionMaxValue) maxValue = sectionMaxValue;
			},
		);

		setlineGraphMaxAxisValue(maxValue);

		return undefined;
	}, [selectedStatisticsType]);

	useEffect(() => {
		const filteredSwarmPlotData = statistics.scores_by_no.find(
			(elem) => elem.no === selectedStatisticsType,
		);
		if (!filteredSwarmPlotData) return undefined;
		setSwarmPlotData([filteredSwarmPlotData]);
		return undefined;
	}, []);

	function handleOk() {
		setShowChartModal(false);
	}

	return (
		<Portal>
			<div className="relative">
				<h1 className="sr-only">문항에 대한 통계 그래프</h1>
				<div className="background">
					<div className="modal__chart bg-white rounded-lg w-full h-full overflow-scroll overflow-y-auto overflow-x-hidden">
						<div className="sticky top-0 right-5 flex justify-end">
							<CloseBtn setShowModal={setShowChartModal} />
						</div>

						<div className="w-[90%] mx-auto flex items-center justify-center min-h-[100px] mt-6 mb-4">
							{acc.length > 0 ? (
								acc.map((elem) => (
									<div
										key={elem[0]}
										className="flex w-[300px] 2xl:w-[400px] h-[170px] 2xl:h-[220px] rounded-lg shadow-xl bg-white relative mr-5"
									>
										<span className="pl-2 pt-2 2xl:pl-4 2xl:pt-4 xl:text-[18px] text-black/60 font-bold">
											문항별 {elem[0]} 정확도
										</span>
										<div className="flex justify-center items-center w-[160px] 2xl:w-[190px] absolute right-1 top-4 2xl:top-7 p-3">
											<CircularProgressbar
												value={Number(elem[1].slice(0, -1))}
												text={`${elem[1]}`}
												strokeWidth={12}
												styles={buildStyles({
													// Rotation of path and trail, in number of turns (0-1)
													rotation: 0,

													// Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
													strokeLinecap: 'butt',

													// Text size
													textSize: '18px',

													// How long animation takes to go from one percentage to another, in seconds
													pathTransitionDuration: 0.5,

													// Can specify path transition in more detail, or remove it entirely
													// pathTransition: 'stroke-dashoffset 3s ease 1s',

													// Colors
													pathColor: `#1592e5`,
													textColor: '#1592e5',
													trailColor: '#d6d6d6',
													backgroundColor: '#3e98c7',
												})}
											/>
										</div>
									</div>
								))
							) : (
								<div className="border w-full h-full flex justify-center items-center shadow-md">
									<span className="block p-5 opacity-60">
										정확도 데이터가 없습니다.
									</span>
								</div>
							)}
						</div>

						<div className="w-[90%] mx-auto">
							<h2 className="font-bold text-[18px]">문항별 기계 채점 점수</h2>
							<div className="flex justify-center items-center mb-10">
								{predict &&
									predict.map((elem: any, idx) => (
										<ConfusionColumnChart
											idx={`modal-${idx}`}
											key={`modal-${elem[0]}`}
											title={elem[0]}
											allData={elem[1]}
											xData={Object.keys(elem[1])}
											yData={Object.values(elem[1])}
											graphMaxAxisValue={lineGraphMaxAxisValue}
											width={
												idx === 2
													? (window.innerWidth * 0.7) / 3
													: (window.innerWidth * 0.7) / 3 - 12
											}
										/>
									))}
							</div>
						</div>

						<div className={cls('w-[90%] mx-auto mb-14')}>
							<h2 className="font-bold text-[18px]">
								전체 평균 대비 문항별 평균
							</h2>
							<div className="w-full h-full">
								{statistics.scores_by_no && (
									<ModalColumnLineChart
										selectedStatisticsType={selectedStatisticsType}
										scores={statistics.scores_by_no}
										idx="0"
										title="평균 차트"
										width={window.innerWidth * 0.7}
										height={500}
									/>
								)}
							</div>
						</div>

						<div className="flex justify-center w-60 mx-auto mb-10">
							<Button
								className="block w-44 h-44 mr-3 focus:outline-solid focus:outline-4 focus:outline-black hover:bg-black"
								type="primary"
								size="large"
								ghost
								block
								onClick={() => handleOk()}
							>
								확인
							</Button>
						</div>
					</div>
				</div>
			</div>
		</Portal>
	);
}

export default React.memo(ChartModal);
