import React, { useEffect } from 'react';

import Chart, { BarChartOptions } from '@toast-ui/chart';

interface Props {
	idx: number | string;
	title?: string;
	allData: any;
	xData?: string[];
	yData?: number[];
	width?: number;
	height?: number;
	graphMaxAxisValue: number;
}

function ConfusionColumnChart({
	idx,
	title,
	allData,
	xData,
	yData,
	width,
	height,
	graphMaxAxisValue,
}: Props) {
	useEffect(() => {
		const data = {
			categories: xData as string[],
			series: [
				{
					name: `${title}`,
					data: yData as number[],
				},
			],
		};

		const options: BarChartOptions = {
			// responsive: { animation: { duration: 300 } },
			tooltip: {
				formatter: (value, tooltipDataInfo) => `${value}개`,
			},
			chart: {
				title: {
					text: `${title}`,
					offsetY: -5,
					align: 'center',
				},
				animation: {
					duration: 3000,
				},
				width: 'auto',
				height: 'auto',
			},
			series: {
				selectable: true,
				dataLabels: {
					visible: true,
				},
			},
			exportMenu: {
				visible: false,
			},
			legend: {
				visible: true,
				align: 'bottom',
				showCheckbox: false,
			},
			xAxis: {
				title: {
					text: 'Scores',
				},
			},
			yAxis: {
				title: {
					text: 'Number of questions',
					// offsetY: -10,
				},
				scale: {
					max: graphMaxAxisValue,
				},
			},
			theme: {
				chart: {
					fontFamily: 'Verdana',
				},
				title: {
					fontSize: 20,
					fontWeight: 400,
					color: 'rgba(0,0,0,0.7)',
				},
				xAxis: {
					title: {
						color: 'rgba(0,0,0,0.5)',
					},
				},
				yAxis: {
					title: {
						color: 'rgba(0,0,0,0.5)',
					},
				},
			},
		};
		const el = document.getElementById(`confusingBar_${idx}`);
		if (el === null) return undefined;

		const confusingBar = Chart.columnChart({ el, data, options });

		return () => {
			confusingBar.destroy();
		};
	}, []);

	return (
		<div
			id={`confusingBar_${idx}`}
			className={`${idx === 2 ? '' : ' mr-3'}`}
			style={{
				width,
				height,
			}}
		/>
	);
}

export default React.memo(ConfusionColumnChart);

ConfusionColumnChart.defaultProps = {
	title: 'TETSTSDFSDF',
	xData: ['1', '2', '3', '4', '5'],
	yData: ['5', '4', '3', '2', '1'],
	width: 100,
	height: 350,
};
