import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useAppDispatch } from '@/app/hooks';
import { setInit } from '@/app/reducer/detailpage/detailSlice';
import {
	getScrollBooleanFromSession,
	handleScroll,
	QUESTION_LIST,
	saveScrollBoolToSession,
	saveScrollIndexToSession,
} from '@/utils';
import { MAIN_SCROLL_HANDLE } from '@/utils/scroll';
import { saveCurrentPage } from '@/app/reducer/postpage/postSlice';
// <-- import styles to be used

export default function LinkButton({
	url,
	fontSize,
	endScroll,
}: {
	url: string;
	fontSize?: string;
	endScroll?: boolean;
}) {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (endScroll) {
			saveScrollBoolToSession(MAIN_SCROLL_HANDLE, true);
		}
	}, []);

	function goParamPage(pageURL: string) {
		if (pageURL === '-1') {
			navigate(-1);
		} else navigate(pageURL);
	}

	return (
		<button
			type="button"
			className="flex flex-col justify-center items-center
           text-main-blue/30 hover:text-main-blue/80 hover:font-bold mt-1 px-2 py-1"
			onClick={() => {
				goParamPage(url);
			}}
		>
			<FontAwesomeIcon
				icon={solid('circle-arrow-left')}
				style={{ fontSize, maxWidth: '70px' }}
			/>
		</button>
	);
}

LinkButton.defaultProps = {
	fontSize: '10px',
	endScroll: false,
};
