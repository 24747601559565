import React from 'react';

/** redux */
import { useAppSelector, useAppDispatch } from '@/app/hooks';

/**
 * 참조할 state를 가진 Store의 이름을 입력해준다.(store.ts)
 * ```reducer: {
 * 	main: mainReducer,
 * 	post: postListReducer,
 * },```
 * 이후 return 되는 useAppDispatch() 인수로 reducer를 호출해서 사용해준다.

 * @params {string} selectStore Store를 return 받기때문에 State 값을 입력해준다.
 * @return [store, useAppDispatch()]
 */
export default function useRedux(selectStore: string) {
	const store = useAppSelector(
		(state: { [key: string]: any }) => state[selectStore],
	);

	return store;
}
