import React, { useEffect } from 'react';

interface Props {
	className?: string;
}

const path = `${process.env.PUBLIC_URL}/natmal-logo.png`;

export default function Footer({ className }: Props) {
	return (
		<footer
			className={`w-full bottom-0 flex flex-col justify-center items-center z-10 h-28${
				className !== '' ? ` ${className}` : ``
			}`}
		>
			{/* `${process.env.PUBLIC_URL}/natmal-logo.png` */}
			<img className="w-36 mb-1" src="/natmal-logo.png" alt="natmal-logo" />
			<small className="text-[#bdbdbd]">Copyright © Natmal Corp. 2022</small>
		</footer>
	);
}

Footer.defaultProps = {
	className: '',
};
