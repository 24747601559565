import React from 'react';

import { InputBox, LinkBtn } from '@/components';
import { useRedux } from '@/hooks';

const Infos = [
	{
		key: '_nsentence',
		kor: '문장 수',
		eng: 'sentenceCnt',
		val: 0,
	},
	// {
	// 	key: '_nparam',
	// 	kor: '문단 수',
	// 	eng: 'paragraphCnt',
	// 	val: 0,
	// },
	{
		key: '_nchar',
		kor: '글자 수(공백 포함)',
		eng: 'letterCnt_WithWhiteSpace',
		val: 0,
	},
	{
		key: '_nchar_ex',
		kor: '글자 수(공백 제외)',
		eng: 'letterCnt_NoWhiteSpace',
		val: 0,
	},
	{
		key: '_neojul',
		kor: '어절 수',
		eng: 'wordCnt',
		val: 0,
	},
	{
		key: '_neojul_per_sent',
		kor: '문장당 평균 어절 수',
		eng: 'sentencePerAvgWord',
		val: 0,
	},

	{
		key: '_npage',
		kor: '200자 원고지(장)',
		eng: 'paper',
		val: 0,
	},
];

function DetailHeader() {
	const { lqInfos } = useRedux('detail');

	return (
		<div className="flex items-center mb-2">
			{Infos.map((info) => (
				<span
					key={info.eng}
					className="responsive-detail-header-text first:ml-0 ml-3"
				>
					<InputBox
						type="span"
						containerClass="flex items-center justify-center"
						keyText={info.kor}
						keyClass="border p-[3px] text-[15px]"
						valText={lqInfos[info.key]}
						valClass="text-main-blue text-[15px] font-bold pl-1"
					/>
				</span>
			))}
		</div>
	);
}

export default React.memo(DetailHeader);
