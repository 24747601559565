import React from 'react';
import ReactDOM from 'react-dom/client';
import '@/style/index.scss';

/** react-query */
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

/** redux */
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from '@/app/store';

/** router */
// import Scrollbar from 'react-smooth-scrollbar';
import Chart from 'chart.js';

import App from './App';
import reportWebVitals from './reportWebVitals';

// import { Scroll } from './components';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);

root.render(
	// <React.StrictMode>
	<Provider store={store}>
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				{/* devtools */}
				{/* <ReactQueryDevtools initialIsOpen={false} /> */}
				{/* <Scroll> */}
				{/* <Scrollbar
						damping={0.6}
						thumbMinSize={20}
						renderByPixels
						alwaysShowTracks
						continuousScrolling
					> */}
				<App />
				{/* </Scrollbar> */}
				{/* </Scroll> */}
			</BrowserRouter>
		</QueryClientProvider>
	</Provider>,
	// </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
