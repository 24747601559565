/* eslint-disable import/no-cycle */
export { USER_TOKEN, setCookie, getCookie, deleteCookie } from './cookie';
export { dayToKST, timeToKST, dateString } from './date';
export {
	POSTS,
	LOGIN,
	DOMAIN,
	BASE_URL,
	AUTH_LOGIN,
	QUESTION_LIST,
	QUESTIONS,
	PREDICT,
	UPLOAD,
	Q_TYPES,
} from './url';

export { handleScroll } from './scroll';
export { TEXT_LIMIT } from './ detail';
export { WriteMode, DataType } from './question';
export {
	saveQuestionToSession,
	getQuestionFromSession,
	saveQuestionInfoToSession,
	getQuestionInfoFromSession,
	Q_CHECK_STATUS,
	saveScrollIndexToSession,
	getScrollIndexFromSession,
	saveScrollBoolToSession,
	getScrollBooleanFromSession,
} from './storage';

export { ChartMode } from './chart';

export {
	acceptableFileName,
	tableHeader,
	excelSample,
	excelFileType,
	excelFileExtension,
	excelSampleDownload,
	excelAllDownload,
} from './excel';

export function cls(...classnames: string[]) {
	return classnames.join(' ');
}
