import React, { useEffect, useState } from 'react';
import type { InputBoxProps } from '@/types';

export default function InputBox({
	type,
	containerClass,
	keyText,
	valText,
	keyClass,
	valClass,
}: InputBoxProps) {
	const [text, setText] = useState(valText);

	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		let temp = e.target.value;
		if (temp.length > 2) temp = temp.substring(0, 2);
		setText(temp);
	}

	useEffect(() => {
		if (valText) {
			setText(valText);
		}
	}, [valText]);

	return (
		<div className={containerClass}>
			{type === 'span' ? (
				<>
					<pre className={`${keyClass === '' ? '' : ` ${keyClass}`}`}>
						{keyText}
					</pre>
					<span className={`${valClass === '' ? '' : ` ${valClass}`}`}>
						{valText}
					</span>
				</>
			) : (
				<label
					htmlFor="LabelWithButton"
					className={`${keyClass === '' ? '' : ` ${keyClass}`} `}
				>
					<span>{keyText}</span>
					<input
						type={type}
						id="LabelWithButton"
						defaultValue={text}
						className={`${valClass === '' ? '' : ` ${valClass}`}`}
						onChange={(e) => handleChange(e)}
					/>
				</label>
			)}
		</div>
	);
}

InputBox.defaultProps = {
	type: 'text',
	containerClass: '',
	keyText: '',
	valText: '',
	keyClass: '',
	valClass: '',
	onChange: () => null,
};
