/* eslint-disable no-shadow */
export const enum WriteMode {
	update = 'update',
	create = 'create',
}

export const DataType = {
	과제: '과제',
	전달: '전달',
	언어: '언어',
};
