import React from 'react';
import axios from 'axios';

/** component */
import Swal from 'sweetalert2';
import { PostItem } from '@/components';

/** redux */
import { useRedux } from '@/hooks';
import { useAppDispatch } from '@/app/hooks';
import {
	saveCheckedArrToRedux,
	saveFetchPostDataToRedux,
	setRenderState,
} from '@/app/reducer/postpage/postSlice';

/** types */
import { CustomError, PostData } from '@/types';

/** utils */
import { BASE_URL, getCookie, POSTS, USER_TOKEN } from '@/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { HOMEPAGE } from '@/utils/url';

interface PostItemContainerProps {
	idx: number;
}

export default function PostItemContainer({ idx }: PostItemContainerProps) {
	const { checkedDataArr, fetchPostDataArr } = useRedux('post');
	// eslint-disable-next-line camelcase
	const { id } = fetchPostDataArr[idx];
	const { id: urlId } = useParams();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	/** 선택한 데이터 체크박스 동작 기능 */
	function handleChecked(selectId: number) {
		let data = [...checkedDataArr];
		if (checkedDataArr.includes(selectId)) {
			data = data.filter((elem) => elem !== selectId);
		} else {
			data.push(selectId);
		}
		dispatch(saveCheckedArrToRedux(data));
	}

	/** 선택한 데이터 삭제 */
	function handleDeleteData(
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		selectId: number,
	) {
		const [targetData] = [
			...fetchPostDataArr.filter((data: any) => data.id === selectId),
		];

		Swal.fire({
			icon: 'warning',
			html: `<p><span style="color:red;">${targetData.tester_id}</span> 글을 정말 삭제하시겠습니까?</p>`,
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: '예',
			cancelButtonText: '아니오',
			heightAuto: false,
		}).then((result) => {
			//  취소버튼 클릭 시, http 요청이 추가되어야 함.
			if (result.isConfirmed) {
				// Yes 버튼 클릭했을 때
				(async () => {
					try {
						const cookieHash = getCookie(USER_TOKEN);
						const res = await axios.delete(
							`/${BASE_URL}/${POSTS}/${selectId}`,
							{
								headers: {
									Authorization: cookieHash,
								},
							},
						);
						if (res.data.status === 'success') {
							dispatch(
								saveFetchPostDataToRedux(
									fetchPostDataArr.filter(
										(post: PostData) => post.id !== +selectId,
									),
								),
							);
							dispatch(setRenderState(true));
							Swal.fire({
								title: 'Deleted',
								text: '정상적으로 삭제되었습니다.',
								icon: 'success',
								heightAuto: false,
							});
							/** 애니메이션 처리 */
						}
					} catch (error: unknown) {
						const err = error as CustomError;
						console.error(err);
						Swal.fire({
							title: 'Error',
							text: `${err.message}`,
							icon: 'warning',
							heightAuto: false,
						});
					}
				})();
			}
		});
	}

	function handleKeyUpWhenEnter(e: React.KeyboardEvent<HTMLButtonElement>) {
		if (e.key !== 'Enter') return;
		handleChecked(id);
	}

	function handleEditBtn() {
		navigate(`/${HOMEPAGE}/${urlId}/${POSTS}/${id}`);
	}

	return (
		<PostItem
			idx={idx}
			postData={fetchPostDataArr[idx]}
			handleEditBtn={() => handleEditBtn()}
			handleChecked={() => handleChecked(id)}
			handleDeleteData={(e) => handleDeleteData(e, id)}
			handleKeyUpWhenEnter={(e) => handleKeyUpWhenEnter(e)}
		/>
	);
}
