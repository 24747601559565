import { configureStore } from '@reduxjs/toolkit';
import {
	postReducer,
	checkReducer,
	detailReducer,
	mainReducer,
	chartReducer,
	questionReducer,
	loginReducer,
} from '@/app/reducer';

export const store = configureStore({
	reducer: {
		check: checkReducer,
		post: postReducer,
		detail: detailReducer,
		main: mainReducer,
		chart: chartReducer,
		question: questionReducer,
		login: loginReducer,
	},
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
