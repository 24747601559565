import React, { useEffect } from 'react';
import { DetailDraft, NewPost } from '@/components';

import type { SetStateAction } from 'react';

export default function NewPostPage({
	setNewPost,
}: {
	setNewPost: React.Dispatch<SetStateAction<boolean>>;
}) {
	return (
		<div className="responsive-screen mx-auto flex flex-col justify-center h-full p-7 mt-32">
			<h1 className="sr-only">새로운 글 작성</h1>
			<NewPost />
		</div>
	);
}
