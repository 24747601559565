import {
	saveScrollBoolToSession,
	getScrollBooleanFromSession,
} from './storage';

export const MAIN_SCROLL_HANDLE = 'main-scroll-handle';

export function handleScroll() {
	window.scrollTo({
		top: 0,
		left: 0,
		behavior: 'smooth',
	});
}

export function mainPageScrollHandle() {
	window.scrollTo({
		top: getScrollBooleanFromSession(MAIN_SCROLL_HANDLE) ? 1800 : 0,
		left: 0,
		behavior: 'smooth',
	});
}
