export const POSTS = 'posts';
export const LOGIN = 'login';
export const AUTH_LOGIN = 'auth/login';
export const DOMAIN = 'http://localhost:3000';
export const BASE_URL = 'api';
export const QUESTION_LIST = 'question_list';
export const QUESTIONS = 'questions';
export const PREDICT = 'predict';
export const UPLOAD = 'upload';
export const HOMEPAGE = QUESTION_LIST;
export const Q_TYPES = 'qtypes';
