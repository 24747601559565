import React from 'react';

/** components */
import { PostItemContainer } from '@/containers';

/** types */
import { PostData } from '@/types';

interface FetchDataProps {
	fetchPostDataArr: PostData[];
}

export default function PostList({ fetchPostDataArr }: FetchDataProps) {
	return (
		<ul className="responsive-grid w-full h-full grid gap-x-6 gap-y-10">
			{fetchPostDataArr.map((data: PostData, idx: number) => (
				<PostItemContainer key={data.id} idx={idx} />
			))}
		</ul>
	);
}
